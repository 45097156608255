<template>
  <div class="table-container_2">
    <div class="table-header-container">
      <button v-if="!disabled" @click="exportToExcel" class="export-button">
        <i class="fas fa-download"></i>
      </button>
    </div>
    <table class="a-table">
      <thead>
        <tr>
          <th :colspan="numColspan" class="table-title">{{ tableTitle }}</th>
        </tr>
        <tr v-if="subtable1 || subtable2">
          <th v-if="subtable1" :colspan="subnumColspan" class="table-subtitle">
            {{ subtable1 }}
          </th>
          <th v-if="subtable2" :colspan="subnumColspan" class="table-subtitle">
            {{ subtable2 }}
          </th>
        </tr>
        <tr v-if="subnum1 !== undefined || subnum2 !== undefined">
          <th v-if="subnum1" :colspan="dataColspan" class="table-data">
            <span v-if="enablePopup && hasModalData" @click="openModal">
              {{ subnum1 }}
            </span>
            <span v-else>{{ subnum1 }}</span>
          </th>
          <th v-if="subnum2" :colspan="dataColspan" class="table-data">
            <span v-if="enablePopup && hasModalData" @click="openModal">
              {{ subnum2 }}
            </span>
            <span v-else>{{ subnum2 }}</span>
          </th>
        </tr>
        <tr>
          <th
            v-for="(header, index) in columnHeaders"
            :key="'header-' + index"
            class="table-header"
          >
            <div
              class="header-with-filter"
              ref="filterButton"
              :data-index="index"
            >
              <span>{{ header }}</span>
              <i
                v-if="enableFilters[index]"
                class="fas fa-filter"
                @click="toggleFilterDropdown(index)"
              ></i>
              <div v-if="filterDropdownVisible[index]" class="dropdown-content">
                <ul class="filter-options-list">
                  <li>
                    <input
                      type="text"
                      v-model="searchQuery[index]"
                      class="filter-search-input"
                      placeholder="Buscar..."
                    />
                  </li>
                  <li
                    v-if="isNumericField(index)"
                    @click="sortData(index, 'asc', 'number')"
                  >
                    Ordenar de menor a mayor
                  </li>
                  <li
                    v-if="isNumericField(index)"
                    @click="sortData(index, 'desc', 'number')"
                  >
                    Ordenar de mayor a menor
                  </li>
                  <li
                    v-if="isDateField(index)"
                    @click="sortData(index, 'asc', 'date')"
                  >
                    Ordenar de más antigua a más reciente
                  </li>
                  <li
                    v-if="isDateField(index)"
                    @click="sortData(index, 'desc', 'date')"
                  >
                    Ordenar de más reciente a más antigua
                  </li>
                  <li
                    v-if="isTextField(index)"
                    @click="sortData(index, 'asc', 'text')"
                  >
                    Ordenar de A a Z
                  </li>
                  <li
                    v-if="isTextField(index)"
                    @click="sortData(index, 'desc', 'text')"
                  >
                    Ordenar de Z a A
                  </li>
                  <li @click="clearFilter(index)">All</li>
                  <li
                    v-for="option in filteredFilterOptions(index)"
                    :key="option"
                    :class="{
                      'selected-option': isSelectedFilter(option, index),
                    }"
                    @click="toggleTextFilter(option, index)"
                  >
                    {{ option }}
                  </li>
                </ul>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dato, rowIndex) in filteredData" :key="'row-' + rowIndex">
          <td
            v-for="(field, fieldIndex) in rowFields"
            :key="'field-' + fieldIndex"
          >
            {{ dato[field.key] }}
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="showModal && hasModalData"
      class="modal-overlay"
      @click="closeModal"
    >
      <div class="modal-content" @click.stop>
        <span class="modal-close" @click="closeModal">&times;</span>
        <div class="modal-table-container" @click="closeAllDropdowns">
          <table class="modal-table">
            <thead>
              <tr>
                <th :colspan="modalNumColspan" class="modal-table-title">
                  {{ modalTableTitle }}
                  <button
                    v-if="!modalExportDisabled"
                    @click="exportToExcel"
                    class="modal-export-button"
                  >
                    <i class="fas fa-download"></i>
                  </button>
                </th>
              </tr>
              <tr v-if="modalSubtable1 || modalSubtable2">
                <th
                  v-if="modalSubtable1"
                  :colspan="modalSubnumColspan"
                  class="table-subtitle"
                >
                  {{ modalSubtable1 }}
                </th>
                <th
                  v-if="modalSubtable2"
                  :colspan="modalSubnumColspan"
                  class="table-subtitle"
                >
                  {{ modalSubtable2 }}
                </th>
              </tr>
              <tr
                v-if="modalSubnum1 !== undefined || modalSubnum2 !== undefined"
              >
                <th
                  v-if="modalSubnum1"
                  :colspan="modalDataColspan"
                  class="table-data"
                >
                  {{ modalSubnum1 }}
                </th>
                <th
                  v-if="modalSubnum2"
                  :colspan="modalDataColspan"
                  class="table-data"
                >
                  {{ modalSubnum2 }}
                </th>
              </tr>
              <tr>
                <th
                  v-for="(header, index) in modalHeaders"
                  :key="'modal-header-' + index"
                  class="table-header"
                >
                  <div
                    class="header-with-filter"
                    ref="modalFilterButton"
                    :data-index="index"
                    @click.stop
                  >
                    <span>{{ header }}</span>
                    <i
                      v-if="modalEnableFilters[index]"
                      class="fas fa-filter"
                      @click="toggleModalFilterDropdown(index)"
                    ></i>
                    <div
                      v-if="modalFilterDropdownVisible[index]"
                      class="dropdown-content"
                    >
                      <ul class="filter-options-list">
                        <li>
                          <input
                            type="text"
                            v-model="modalSearchQuery[index]"
                            class="filter-search-input"
                            placeholder="Buscar..."
                          />
                        </li>
                        <li
                          v-if="isNumericModalField(index)"
                          @click="sortModalData(index, 'asc', 'number')"
                        >
                          Ordenar de menor a mayor
                        </li>
                        <li
                          v-if="isNumericModalField(index)"
                          @click="sortModalData(index, 'desc', 'number')"
                        >
                          Ordenar de mayor a menor
                        </li>
                        <li
                          v-if="isDateModalField(index)"
                          @click="sortModalData(index, 'asc', 'date')"
                        >
                          Ordenar de más antigua a más reciente
                        </li>
                        <li
                          v-if="isDateModalField(index)"
                          @click="sortModalData(index, 'desc', 'date')"
                        >
                          Ordenar de más reciente a más antigua
                        </li>
                        <li
                          v-if="isTextModalField(index)"
                          @click="sortModalData(index, 'asc', 'text')"
                        >
                          Ordenar de A a Z
                        </li>
                        <li
                          v-if="isTextModalField(index)"
                          @click="sortModalData(index, 'desc', 'text')"
                        >
                          Ordenar de Z a A
                        </li>
                        <li @click="clearModalFilter(index)">All</li>
                        <li
                          v-for="option in filteredModalFilterOptions(index)"
                          :key="option"
                          :class="{
                            'selected-option': isSelectedModalFilter(
                              option,
                              index
                            ),
                          }"
                          @click="toggleModalTextFilter(option, index)"
                        >
                          {{ option }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in filteredModalData"
                :key="'modal-row-' + rowIndex"
              >
                <td
                  v-for="(field, fieldIndex) in modalRowFields"
                  :key="'modal-field-' + fieldIndex"
                >
                  {{ row[field.key] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from "vue";

export default defineComponent({
  name: "InfoTable",
  props: {
    Info: { type: Array, required: false, default: () => [] },
    columnHeaders: { type: Array, required: false, default: () => [] },
    rowFields: { type: Array, required: false, default: () => [] },
    tableTitle: { type: [Number, String], required: false },
    subtable1: { type: [Number, String], required: false },
    subtable2: { type: [Number, String], required: false },
    numColspan: { type: [Number, String], required: false },
    subnumColspan: { type: [Number, String], required: false },
    dataColspan: { type: [Number, String], required: false },
    disabled: { type: Boolean, required: false },
    modalExportDisabled: { type: Boolean, required: false },
    enablePopup: { type: Boolean, required: false },
    subnum1: { type: [Number, String], required: false },
    subnum2: { type: [Number, String], required: false },
    modalTableTitle: { type: [Number, String], required: false },
    modalData: { type: Array, required: false, default: () => [] },
    modalHeaders: { type: Array, required: false, default: () => [] },
    modalRowFields: { type: Array, required: false, default: () => [] },
    modalSubtable1: { type: [Number, String], required: false },
    modalSubtable2: { type: [Number, String], required: false },
    modalNumColspan: { type: [Number, String], required: false },
    modalSubnumColspan: { type: [Number, String], required: false },
    modalDataColspan: { type: [Number, String], required: false },
    modalSubnum1: { type: [Number, String], required: false },
    modalSubnum2: { type: [Number, String], required: false },
    enableFilters: { type: Array, required: false, default: () => [] },
    modalEnableFilters: { type: Array, required: false, default: () => [] },
  },

  setup(props) {
    const showModal = ref(false);
    const selectedFilters = ref([]);
    const filterDropdownVisible = ref([]);
    const searchQuery = ref([]);
    const selectedModalFilters = ref([]);
    const modalFilterDropdownVisible = ref([]);
    const modalSearchQuery = ref([]);
    const sortIndex = ref(null);
    const sortOrder = ref(null);
    const modalSortIndex = ref(null);
    const modalSortOrder = ref(null);

    onMounted(() => {
      filterDropdownVisible.value = props.columnHeaders.map(() => false);
      modalFilterDropdownVisible.value = props.modalHeaders.map(() => false);
      searchQuery.value = props.columnHeaders.map(() => "");
      modalSearchQuery.value = props.modalHeaders.map(() => "");
      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    const filterOptions = computed(() => {
      if (!props.Info || !props.rowFields) return [];
      return props.rowFields.map((field) => {
        const uniqueValues = new Set(
          props.Info.map((row) => row[field.key]?.toString())
        );
        return Array.from(uniqueValues);
      });
    });

    const filteredFilterOptions = (index) => {
      return filterOptions.value[index].filter((option) =>
        option.toLowerCase().includes(searchQuery.value[index].toLowerCase())
      );
    };

    const modalFilterOptions = computed(() => {
      if (!props.modalData || !props.modalRowFields) return [];
      return props.modalRowFields.map((field) => {
        const uniqueValues = new Set(
          props.modalData.map((row) => row[field.key]?.toString())
        );
        return Array.from(uniqueValues);
      });
    });

    const filteredModalFilterOptions = (index) => {
      return modalFilterOptions.value[index].filter((option) =>
        option
          .toLowerCase()
          .includes(modalSearchQuery.value[index].toLowerCase())
      );
    };

    const handleClickOutside = (event) => {
      const clickedInsideDropdown =
        event.target.closest(".header-with-filter") ||
        event.target.closest(".dropdown-content");

      if (!clickedInsideDropdown) {
        closeAllDropdowns();
      }
    };

    const closeAllDropdowns = () => {
      filterDropdownVisible.value = filterDropdownVisible.value.map(
        () => false
      );
      modalFilterDropdownVisible.value = modalFilterDropdownVisible.value.map(
        () => false
      );
    };

    const isNumericField = (index) => {
      return props.rowFields[index]?.format === "number";
    };

    const isDateField = (index) => {
      return props.rowFields[index]?.format === "date";
    };

    const isTextField = (index) => {
      return props.rowFields[index]?.format === "text";
    };

    const isNumericModalField = (index) => {
      return props.modalRowFields[index]?.format === "number";
    };

    const isDateModalField = (index) => {
      return props.modalRowFields[index]?.format === "date";
    };

    const isTextModalField = (index) => {
      return props.modalRowFields[index]?.format === "text";
    };

    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split("-");
      return new Date(year, month - 1, day);
    };

    const sortData = (index, order, type) => {
      sortIndex.value = index;
      sortOrder.value = order;

      filteredData.value = [...filteredData.value].sort((a, b) => {
        let aValue, bValue;

        if (type === "date") {
          aValue = parseDate(a[props.rowFields[index].key]);
          bValue = parseDate(b[props.rowFields[index].key]);
        } else if (type === "text") {
          aValue = a[props.rowFields[index].key]?.toString() || "";
          bValue = b[props.rowFields[index].key]?.toString() || "";
        } else {
          aValue = parseFloat(a[props.rowFields[index].key]) || 0;
          bValue = parseFloat(b[props.rowFields[index].key]) || 0;
        }

        return order === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });

      closeAllDropdowns();
    };

    const sortModalData = (index, order, type) => {
      modalSortIndex.value = index;
      modalSortOrder.value = order;

      filteredModalData.value = [...filteredModalData.value].sort((a, b) => {
        let aValue, bValue;

        if (type === "date") {
          aValue = parseDate(a[props.modalRowFields[index].key]);
          bValue = parseDate(b[props.modalRowFields[index].key]);
        } else if (type === "text") {
          aValue = a[props.modalRowFields[index].key]?.toString() || "";
          bValue = b[props.modalRowFields[index].key]?.toString() || "";
        } else {
          aValue = parseFloat(a[props.modalRowFields[index].key]) || 0;
          bValue = parseFloat(b[props.modalRowFields[index].key]) || 0;
        }

        return order === "asc"
          ? aValue < bValue
            ? -1
            : 1
          : aValue > bValue
          ? -1
          : 1;
      });

      closeAllDropdowns();
    };

    const filteredData = computed(() => {
      let data = [...props.Info];

      selectedFilters.value.forEach((filters, index) => {
        if (!filters || filters.length === 0) return;
        data = data.filter((row) => {
          const cellValue = row[props.rowFields[index].key]?.toString() || "";
          return filters.includes(cellValue);
        });
      });

      if (sortIndex.value !== null && sortOrder.value !== null) {
        const fieldType = props.rowFields[sortIndex.value]?.format;
        if (fieldType === "date") {
          data.sort((a, b) => {
            const aValue = parseDate(a[props.rowFields[sortIndex.value].key]);
            const bValue = parseDate(b[props.rowFields[sortIndex.value].key]);
            return sortOrder.value === "asc"
              ? aValue - bValue
              : bValue - aValue;
          });
        } else if (fieldType === "number") {
          data.sort((a, b) => {
            const aValue =
              parseFloat(a[props.rowFields[sortIndex.value].key]) || 0;
            const bValue =
              parseFloat(b[props.rowFields[sortIndex.value].key]) || 0;
            return sortOrder.value === "asc"
              ? aValue - bValue
              : bValue - aValue;
          });
        } else if (fieldType === "text") {
          data.sort((a, b) => {
            const aValue =
              a[props.rowFields[sortIndex.value].key]?.toString() || "";
            const bValue =
              b[props.rowFields[sortIndex.value].key]?.toString() || "";
            return sortOrder.value === "asc"
              ? aValue < bValue
                ? -1
                : 1
              : aValue > bValue
              ? -1
              : 1;
          });
        }
      }

      return data;
    });

    const filteredModalData = computed(() => {
      let data = [...props.modalData];

      selectedModalFilters.value.forEach((filters, index) => {
        if (!filters || filters.length === 0) return;
        data = data.filter((row) => {
          const cellValue =
            row[props.modalRowFields[index].key]?.toString() || "";
          return filters.includes(cellValue);
        });
      });

      if (modalSortIndex.value !== null && modalSortOrder.value !== null) {
        const fieldType = props.modalRowFields[modalSortIndex.value]?.format;
        if (fieldType === "date") {
          data.sort((a, b) => {
            const aValue = parseDate(
              a[props.modalRowFields[modalSortIndex.value].key]
            );
            const bValue = parseDate(
              b[props.modalRowFields[modalSortIndex.value].key]
            );
            return modalSortOrder.value === "asc"
              ? aValue - bValue
              : bValue - aValue;
          });
        } else if (fieldType === "number") {
          data.sort((a, b) => {
            const aValue =
              parseFloat(a[props.modalRowFields[modalSortIndex.value].key]) ||
              0;
            const bValue =
              parseFloat(b[props.modalRowFields[modalSortIndex.value].key]) ||
              0;
            return modalSortOrder.value === "asc"
              ? aValue - bValue
              : bValue - aValue;
          });
        } else if (fieldType === "text") {
          data.sort((a, b) => {
            const aValue =
              a[props.modalRowFields[modalSortIndex.value].key]?.toString() ||
              "";
            const bValue =
              b[props.modalRowFields[modalSortIndex.value].key]?.toString() ||
              "";
            return modalSortOrder.value === "asc"
              ? aValue < bValue
                ? -1
                : 1
              : aValue > bValue
              ? -1
              : 1;
          });
        }
      }

      return data;
    });

    const toggleTextFilter = (option, index) => {
      const currentFilters = selectedFilters.value[index] || [];
      if (currentFilters.includes(option)) {
        selectedFilters.value[index] = currentFilters.filter(
          (item) => item !== option
        );
      } else {
        selectedFilters.value[index] = [...currentFilters, option];
      }
      applyFilters();
    };

    const toggleModalTextFilter = (option, index) => {
      const currentFilters = selectedModalFilters.value[index] || [];
      if (currentFilters.includes(option)) {
        selectedModalFilters.value[index] = currentFilters.filter(
          (item) => item !== option
        );
      } else {
        selectedModalFilters.value[index] = [...currentFilters, option];
      }
      applyFilters();
    };

    const clearFilter = (index) => {
      selectedFilters.value[index] = [];
      applyFilters();
      closeAllDropdowns();
    };

    const clearModalFilter = (index) => {
      selectedModalFilters.value[index] = [];
      applyFilters();
      closeAllDropdowns();
    };

    const applyFilters = () => {
      selectedFilters.value = [...selectedFilters.value];
      selectedModalFilters.value = [...selectedModalFilters.value];
    };

    const toggleFilterDropdown = (index) => {
      if (filterDropdownVisible.value[index]) {
        filterDropdownVisible.value[index] = false;
      } else {
        closeAllDropdowns();
        filterDropdownVisible.value[index] = true;
      }
    };

    const toggleModalFilterDropdown = (index) => {
      if (modalFilterDropdownVisible.value[index]) {
        modalFilterDropdownVisible.value[index] = false;
      } else {
        closeAllDropdowns();
        modalFilterDropdownVisible.value[index] = true;
      }
    };

    const exportToExcel = () => {
      const ws = XLSX.utils.json_to_sheet(props.Info || [], {
        cellDates: true,
        skipHeader: false,
      });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Data");
      XLSX.writeFile(wb, `${props.tableTitle || "data"}.xlsx`);
    };

    const openModal = () => {
      if (props.enablePopup && hasModalData.value) {
        showModal.value = true;
      }
    };

    const closeModal = () => {
      showModal.value = false;
      closeAllDropdowns();
    };

    const hasModalData = computed(() => {
      return props.modalData && props.modalData.length > 0;
    });

    const isSelectedFilter = (option, index) => {
      return (selectedFilters.value[index] || []).includes(option);
    };

    const isSelectedModalFilter = (option, index) => {
      return (selectedModalFilters.value[index] || []).includes(option);
    };

    return {
      exportToExcel,
      filteredData,
      filteredModalData,
      showModal,
      openModal,
      closeModal,
      hasModalData,
      selectedFilters,
      selectedModalFilters,
      searchQuery,
      modalSearchQuery,
      filterOptions,
      modalFilterOptions,
      filteredFilterOptions,
      filteredModalFilterOptions,
      filterDropdownVisible,
      modalFilterDropdownVisible,
      toggleFilterDropdown,
      toggleModalFilterDropdown,
      toggleTextFilter,
      toggleModalTextFilter,
      clearFilter,
      clearModalFilter,
      isSelectedFilter,
      isSelectedModalFilter,
      closeAllDropdowns,
      isNumericField,
      isDateField,
      isTextField,
      isNumericModalField,
      isDateModalField,
      isTextModalField,
      sortData,
      sortModalData,
    };
  },
});
</script>

<style scoped>
@import "../css/tabla.css";
</style>
