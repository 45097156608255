<template>
  <div class="layout-container">
    <div class="sidemenu-container">
      <SideMenu class="home-sidemenu" />
      <ButtonsMenu class="home-menu" />
    </div>

    <div class="main-container">
      <div class="content-columns">
        <div class="buttons-container">
          <Boton
            icon="fa-solid fa-car-side"
            primaryText="Flota"
            secondaryText="Flota general, unidades nuevas y unidades pre-LOP."
            to="/FlotaUnidadesNuevasPreLOP"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-car-battery"
            primaryText="Servicios"
            secondaryText="Reemplazos activos y unidades fuera de servicio."
            to="/ReemplazosFueraServicio"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-wrench"
            primaryText="Mantenciones - Revisiones"
            secondaryText="Mantenciones pendientes y revisiones técnicas/gases pendientes."
            to="/MantencionesyRevisionesPendientes"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
          <Boton
            icon="fa-solid fa-dollar-sign"
            primaryText="CPA"
            secondaryText="CPA pendientes."
            to="/Cpa"
            :disabled="!isButtonEnabled"
            :class="{ disabled: !isButtonEnabled }"
          />
        </div>

        <div class="tables-container">
          <div class="table-wrapper">
            <Tabla
              tableTitle="% CPA menor a 3 meses"
              :subnum1="
                metrica_cpa_porcentaje === 'no data'
                  ? 'cliente no tiene información de CPA menores a 3 meses disponible'
                  : metrica_cpa_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% CPA menor a 3 meses"
              :modalSubtable1="'# CPA pendientes menores a 3 meses'"
              :modalSubtable2="'% CPA menor a 3 meses'"
              :modalSubnum1="metrica_cpa_num || 0"
              :modalSubnum2="metrica_cpa_porcentaje + '%' || '0%'"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 7 : 6"
              :modalSubnumColspan="
                selectedClient?.name_CL === 'GENERAL' ? 4 : 3
              "
              :modalDataColspan="selectedClient?.name_CL === 'GENERAL' ? 4 : 3"
              :modalData="cpaInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'Código CPA',
                      'PPU',
                      'CPA',
                      'Cargos',
                      'Fecha Apertura',
                      'Días Aperturado',
                    ]
                  : [
                      'Código CPA',
                      'PPU',
                      'CPA',
                      'Cargos',
                      'Fecha Apertura',
                      'Días Aperturado',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'CODIGO_CPA', format: 'number' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CPA', format: 'text' },
                      { key: 'Cargos', format: 'text' },
                      { key: 'FECHA_APERTURA', format: 'date' },
                      { key: 'DIAS_APERTURADO', format: 'number' },
                    ]
                  : [
                      { key: 'CODIGO_CPA', format: 'number' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CPA', format: 'text' },
                      { key: 'Cargos', format: 'text' },
                      { key: 'FECHA_APERTURA', format: 'date' },
                      { key: 'DIAS_APERTURADO', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true, true, true]
                  : [true, true, true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="% Mantenciones por KM pendientes"
              :subnum1="
                metrica_mantenciones4d_porcentaje === 'no data'
                  ? 'cliente no tiene información de mantenciones por KM pendientes disponible'
                  : metrica_mantenciones4d_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Mantenciones por KM pendientes"
              :modalSubtable1="'# Unidades con mantenciones por KM pendientes'"
              :modalSubtable2="'% Mantenciones por KM pendientes'"
              :modalSubnum1="metrica_mantenciones4d_num || 0"
              :modalSubnum2="metrica_mantenciones4d_porcentaje + '%' || '0%'"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 9 : 8"
              :modalSubnumColspan="
                selectedClient?.name_CL === 'GENERAL' ? 5 : 4
              "
              :modalDataColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
              :modalData="mantenciones4dInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'PPU',
                      'Codigo',
                      'Región',
                      'KM 4D',
                      'KM MP',
                      'KM Prox MP',
                      'Fecha MP',
                      'Exceso 4D',
                    ]
                  : [
                      'PPU',
                      'Codigo',
                      'Región',
                      'KM 4D',
                      'KM MP',
                      'KM Prox MP',
                      'Fecha MP',
                      'Exceso 4D',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'KM_4D', format: 'number' },
                      { key: 'KM_MP', format: 'number' },
                      { key: 'KM_PROX_MP', format: 'number' },
                      { key: 'FECHA_MP', format: 'date' },
                      { key: 'EXCESO_4D', format: 'number' },
                    ]
                  : [
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'KM_4D', format: 'number' },
                      { key: 'KM_MP', format: 'number' },
                      { key: 'KM_PROX_MP', format: 'number' },
                      { key: 'FECHA_MP', format: 'date' },
                      { key: 'EXCESO_4D', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true, true, true, true, true]
                  : [true, true, true, true, true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="% Mantenciones por tiempo pendientes"
              :subnum1="
                metrica_mantencionesest_porcentaje === 'no data'
                  ? 'cliente no tiene información de mantenciones por tiempo pendientes disponible'
                  : metrica_mantencionesest_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Mantenciones por tiempo pendientes"
              :modalSubtable1="'# Unidades con mantenciones por tiempo pendientes'"
              :modalSubtable2="'% Mantenciones por tiempo pendientes'"
              :modalSubnum1="metrica_mantencionesest_num || 0"
              :modalSubnum2="metrica_mantencionesest_porcentaje + '%' || '0%'"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 10 : 9"
              :modalSubnumColspan="5"
              :modalDataColspan="5"
              :modalData="mantencionesestInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'PPU',
                      'Codigo',
                      'Región',
                      'KM 4D',
                      'KM EST',
                      'KM MP',
                      'KM Prox MP',
                      'Fecha MP',
                      'Exceso EST',
                    ]
                  : [
                      'PPU',
                      'Codigo',
                      'Región',
                      'KM 4D',
                      'KM EST',
                      'KM MP',
                      'KM Prox MP',
                      'Fecha MP',
                      'Exceso EST',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'KM_4D', format: 'number' },
                      { key: 'KMS_EST', format: 'number' },
                      { key: 'KM_MP', format: 'number' },
                      { key: 'KM_PROX_MP', format: 'number' },
                      { key: 'FECHA_MP', format: 'date' },
                      { key: 'EXCESO_EST', format: 'number' },
                    ]
                  : [
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'KM_4D', format: 'number' },
                      { key: 'KMS_EST', format: 'number' },
                      { key: 'KM_MP', format: 'number' },
                      { key: 'KM_PROX_MP', format: 'number' },
                      { key: 'FECHA_MP', format: 'date' },
                      { key: 'EXCESO_EST', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true, true, true, true, true, true]
                  : [true, true, true, true, true, true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="% Revisiones técnicas pendientes"
              :subnum1="
                metrica_revisionestecnicas_porcentaje === 'no data'
                  ? 'cliente no tiene información de revisiones técnicas pendientes disponible'
                  : metrica_revisionestecnicas_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Revisiones técnicas pendientes"
              :modalSubtable1="'# Unidades con revisiones técnicas pendientes'"
              :modalSubtable2="'% Revisiones técnicas pendientes'"
              :modalSubnum1="metrica_revisionestecnicas_num || 0"
              :modalSubnum2="
                metrica_revisionestecnicas_porcentaje + '%' || '0%'
              "
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
              :modalSubnumColspan="
                selectedClient?.name_CL === 'GENERAL' ? 3 : 2
              "
              :modalDataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
              :modalData="revisionestecnicasInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
                  : ['PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'Rev_TECNICA', format: 'date' },
                    ]
                  : [
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'Rev_TECNICA', format: 'date' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true]
                  : [true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="% Revisiones gases pendientes"
              :subnum1="
                metrica_revisionesgases_porcentaje === 'no data'
                  ? 'cliente no tiene información de revisiones de gases pendientes disponible'
                  : metrica_revisionesgases_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Revisiones gases pendientes"
              :modalSubtable1="'# Unidades con revisiones de gases pendientes'"
              :modalSubtable2="'% Revisiones gases pendientes'"
              :modalSubnum1="metrica_revisionesgases_num || 0"
              :modalSubnum2="metrica_revisionesgases_porcentaje + '%' || '0%'"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
              :modalSubnumColspan="
                selectedClient?.name_CL === 'GENERAL' ? 3 : 2
              "
              :modalDataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
              :modalData="revisionesgasesInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
                  : ['PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'Rev_GASES', format: 'date' },
                    ]
                  : [
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'Rev_GASES', format: 'date' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true]
                  : [true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="Promedio días reemplazo cierre menor a 5 días"
              :subnum1="
                metrica_reemplazos_porcentaje === 'no data'
                  ? 'cliente no tiene información de reeemplazos disponible'
                  : metrica_reemplazos_porcentaje + ' Días'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="Promedio días reemplazo cierre menor a 5 días"
              :modalSubtable1="'# Unidades'"
              :modalSubtable2="'Promedio días'"
              :modalSubnum1="metrica_reemplazos_num || 0"
              :modalSubnum2="metrica_reemplazos_porcentaje + ' Días' || 0"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 10 : 9"
              :modalSubnumColspan="5"
              :modalDataColspan="5"
              :modalData="reemplazosInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'PPU Reemplazo',
                      'Código Reemplazo',
                      'PPU Titular',
                      'Código Titular',
                      'Status Titular',
                      'Región',
                      'Fecha Reemplazo',
                      'Días Reemplazo',
                      'Días Cierre OT',
                    ]
                  : [
                      'PPU Reemplazo',
                      'Código Reemplazo',
                      'PPU Titular',
                      'Código Titular',
                      'Status Titular',
                      'Región',
                      'Fecha Reemplazo',
                      'Días Reemplazo',
                      'Días Cierre OT',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU_REEMPLAZO', format: 'text' },
                      { key: 'CODIGO_REEMPLAZO', format: 'text' },
                      { key: 'PPU_ORIGINAL', format: 'text' },
                      { key: 'CODIGO_ORIGINAL', format: 'text' },
                      { key: 'STATUS_ORIGINAL', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'FECHA_REEMPLAZO', format: 'date' },
                      { key: 'DIAS_REEMPLAZO', format: 'number' },
                      { key: 'DIAS_CIERRE_REMP', format: 'number' },
                    ]
                  : [
                      { key: 'PPU_REEMPLAZO', format: 'text' },
                      { key: 'CODIGO_REEMPLAZO', format: 'text' },
                      { key: 'PPU_ORIGINAL', format: 'text' },
                      { key: 'CODIGO_ORIGINAL', format: 'text' },
                      { key: 'STATUS_ORIGINAL', format: 'text' },
                      { key: 'REGION', format: 'text' },
                      { key: 'FECHA_REEMPLAZO', format: 'date' },
                      { key: 'DIAS_REEMPLAZO', format: 'number' },
                      { key: 'DIAS_CIERRE_REMP', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true, true, true, true, true, true]
                  : [true, true, true, true, true, true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="Promedio días LOP nuevo sin contrato"
              :subnum1="
                metrica_unidadesnuevas_promedio === 'no data'
                  ? 'cliente no tiene información de unidades nuevas disponible'
                  : metrica_unidadesnuevas_promedio + ' Días'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="Promedio días LOP nuevo sin contrato"
              :modalSubtable1="'# Unidades'"
              :modalSubtable2="'Promedio días'"
              :modalSubnum1="metrica_unidadesnuevas_num || 0"
              :modalSubnum2="metrica_unidadesnuevas_promedio || 0"
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 7 : 6"
              :modalSubnumColspan="4"
              :modalDataColspan="4"
              :modalData="unidadesnuevasInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'PPU',
                      'Codigo',
                      'Region',
                      'Ubicación',
                      'Fecha Disponible',
                      // 'Fecha Alquilado',
                      'Días Disponible',
                    ]
                  : [
                      'PPU',
                      'Codigo',
                      'Region',
                      'Ubicación',
                      'Fecha Disponible',
                      // 'Fecha Alquilado',
                      'Días Disponible',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'Patente', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'Region', format: 'text' },
                      { key: 'Ubicacion', format: 'text' },
                      { key: 'FECHA_DISPONIBLE', format: 'date' },
                      // { key: 'FECHA_ALQUILADO', format: 'date' },
                      { key: 'DIAS_DISPONIBLE', format: 'number' },
                    ]
                  : [
                      { key: 'Patente', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'Region', format: 'text' },
                      { key: 'Ubicacion', format: 'text' },
                      { key: 'FECHA_DISPONIBLE', format: 'date' },
                      // { key: 'FECHA_ALQUILADO', format: 'date' },
                      { key: 'DIAS_DISPONIBLE', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true, true, true, true]
                  : [true, true, true, true, true, true, true]
              "
            />
          </div>

          <div class="table-wrapper">
            <Tabla
              tableTitle="% Próximos vencimientos de contratos"
              :subnum1="
                metrica_proximosvencimientos_porcentaje === 'no data'
                  ? 'cliente no tiene información de próximos vencimientos disponible'
                  : metrica_proximosvencimientos_porcentaje + '%'
              "
              :disabled="true"
              :enablePopup="true"
              modalTableTitle="% Próximos vencimientos de contratos"
              :modalSubtable1="'# Próximos vencimientos de contratos'"
              :modalSubtable2="'% Próximos vencimientos de contratos'"
              :modalSubnum1="metrica_proximosvencimientos_num || 0"
              :modalSubnum2="
                metrica_proximosvencimientos_porcentaje + '%' || '0%'
              "
              :modalNumColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
              :modalSubnumColspan="
                selectedClient?.name_CL === 'GENERAL' ? 3 : 2
              "
              :modalDataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
              :modalData="proximosvencimientosInfo"
              :modalHeaders="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      'Cliente',
                      'PPU',
                      'Codigo',
                      'Fin Lease',
                      'Días para Vencimiento Restantes',
                    ]
                  : [
                      'PPU',
                      'Codigo',
                      'Fin Lease',
                      'Días para Vencimiento Restantes',
                    ]
              "
              :modalRowFields="
                selectedClient?.name_CL === 'GENERAL'
                  ? [
                      { key: 'CLIENTE', format: 'text' },
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'FIN_LEASE', format: 'date' },
                      { key: 'DIAS_VENCIMIENTO', format: 'number' },
                    ]
                  : [
                      { key: 'PPU', format: 'text' },
                      { key: 'CODIGO', format: 'text' },
                      { key: 'FIN_LEASE', format: 'date' },
                      { key: 'DIAS_VENCIMIENTO', format: 'number' },
                    ]
              "
              :modalExportDisabled="false"
              :modalEnableFilters="
                selectedClient?.name_CL === 'GENERAL'
                  ? [true, true, true, true, true]
                  : [true, true, true, true]
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref, onBeforeMount } from "vue";
import Tabla from "../components/Tabla_metricas.vue";
import Boton from "../components/Boton.vue";
import SideMenu from "../components/SideMenu.vue";
import ButtonsMenu from "../components/Menu.vue";
import { useClientStore } from "@/stores/clientStore";
import { useSupStore } from "@/stores/supStore";
import useUserStore from "@/stores/userStore";
import { formatNumber, formatDate, formatCurrency } from "../utils/formatters";
import {
  getReporteMetricaCPA,
  getReporteMetricaMantenciones4D,
  getReporteMetricaMantencionesEST,
  getReporteMetricaRevisionesTecnicas,
  getReporteMetricaRevisionesGases,
  getReporteMetricaReemplazos,
  getReporteMetricaUnidadesNuevas,
  getReporteMetricaProximosVencimientos,
} from "@/api/metricas.js";

const userStore = useUserStore();
const userRole = computed(() => userStore.user?.rol);
const userSupervisorId = computed(() => userStore.user?.id);

const clientStore = useClientStore();
const selectedClient = computed(() => clientStore.selectedClient);

const supStore = useSupStore();
const selectedSup = computed(() => supStore.selectedSup);

const cpaInfo = ref([]);
const mantenciones4dInfo = ref([]);
const mantencionesestInfo = ref([]);
const revisionestecnicasInfo = ref([]);
const revisionesgasesInfo = ref([]);
const reemplazosInfo = ref([]);
const unidadesnuevasInfo = ref([]);
const proximosvencimientosInfo = ref([]);

const metrica_cpa_porcentaje = ref(0);
const metrica_cpa_num = ref(0);
const metrica_mantenciones4d_porcentaje = ref(0);
const metrica_mantenciones4d_num = ref(0);
const metrica_mantencionesest_porcentaje = ref(0);
const metrica_mantencionesest_num = ref(0);
const metrica_revisionestecnicas_porcentaje = ref(0);
const metrica_revisionestecnicas_num = ref(0);
const metrica_revisionesgases_porcentaje = ref(0);
const metrica_revisionesgases_num = ref(0);
const metrica_reemplazos_porcentaje = ref(0);
const metrica_reemplazos_num = ref(0);
const metrica_unidadesnuevas_promedio = ref(0);
const metrica_unidadesnuevas_num = ref(0);
const metrica_proximosvencimientos_porcentaje = ref(0);
const metrica_proximosvencimientos_num = ref(0);

const fecha = new Date().toLocaleDateString("en-CA");

const Numero_cliente = computed(() => {
  if (!selectedClient.value) {
    return null;
  }
  const value = selectedClient.value.unikey_CL || null;
  return value;
});

const Sup_Cod_Cartera = computed(() => {
  if (userRole.value === "manager") {
    return userSupervisorId.value || null;
  } else if (userRole.value === "admin") {
    return selectedSup.value?.unikey_SU || null;
  }
  return null;
});

const resetMetrics = () => {
  cpaInfo.value = [];
  mantenciones4dInfo.value = [];
  mantencionesestInfo.value = [];
  revisionestecnicasInfo.value = [];
  revisionesgasesInfo.value = [];
  reemplazosInfo.value = [];
  unidadesnuevasInfo.value = [];
  proximosvencimientosInfo.value = [];
  metrica_cpa_porcentaje.value = 0;
  metrica_cpa_num.value = 0;
  metrica_mantenciones4d_porcentaje.value = 0;
  metrica_mantenciones4d_num.value = 0;
  metrica_mantencionesest_porcentaje.value = 0;
  metrica_mantencionesest_num.value = 0;
  metrica_revisionestecnicas_porcentaje.value = 0;
  metrica_revisionestecnicas_num.value = 0;
  metrica_revisionesgases_porcentaje.value = 0;
  metrica_revisionesgases_num.value = 0;
  metrica_reemplazos_porcentaje.value = 0;
  metrica_reemplazos_num.value = 0;
  metrica_unidadesnuevas_promedio.value = 0;
  metrica_unidadesnuevas_num.value = 0;
  metrica_proximosvencimientos_porcentaje.value = 0;
  metrica_proximosvencimientos_num.value = 0;
};

const loadAllMetrics = async () => {
  if (!Sup_Cod_Cartera.value) {
    return;
  }
  resetMetrics();

  try {
    // .
    if (Sup_Cod_Cartera.value) {
      await Promise.all([
        loadCPAData(),
        loadMantenciones4DData(),
        loadMantencionesESTData(),
        loadRevisionesTecnicasData(),
        loadRevisionesGasesData(),
        loadReemplazosData(),
        loadUnidadesNuevasData(),
        loadProximosVencimientosData(),
      ]);
    }
  } catch (error) {
    console.error("Error al cargar todas las métricas:", error);
  }
};

const loadCPAData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaCPA(fecha, SupId, CliId);

    const reporte = response.datametricacpa;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_cpa_num.value = valoresNumericos[0].menor3Meses;
      metrica_cpa_porcentaje.value = valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      cpaInfo.value = reporte.map((item) => ({
        ...item,
        CPA: formatCurrency(item.CPA),
        Cargos: formatCurrency(item.Cargos),
        FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
        DIAS_APERTURADO: formatNumber(item.DIAS_APERTURADO),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_cpa_porcentaje.value = "no data";
      metrica_cpa_num.value = "no data";
    }
  } catch (error) {
    console.error("Error al cargar los datos de CPA:", error.message);
  }
};

const loadMantenciones4DData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaMantenciones4D(fecha, SupId, CliId);

    const reporte = response.datametricamantenciones4d;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_mantenciones4d_num.value = valoresNumericos[0].pendientes;
      metrica_mantenciones4d_porcentaje.value = valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      mantenciones4dInfo.value = reporte.map((item) => ({
        ...item,
        KM_4D: formatNumber(item.KM_4D),
        KM_MP: formatNumber(item.KM_MP),
        KM_PROX_MP: formatNumber(item.KM_PROX_MP),
        FECHA_MP: formatDate(item.FECHA_MP),
        EXCESO_4D: formatNumber(item.EXCESO_4D),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_mantenciones4d_porcentaje.value = "no data";
      metrica_mantenciones4d_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones 4D:",
      error.message
    );
  }
};

const loadMantencionesESTData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaMantencionesEST(
      fecha,
      SupId,
      CliId
    );

    const reporte = response.datametricamantencionesest;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_mantencionesest_num.value = valoresNumericos[0].pendientes;
      metrica_mantencionesest_porcentaje.value = valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      mantencionesestInfo.value = reporte.map((item) => ({
        ...item,
        KM_4D: formatNumber(item.KM_4D),
        KMS_EST: formatNumber(item.KMS_EST),
        KM_MP: formatNumber(item.KM_MP),
        KM_PROX_MP: formatNumber(item.KM_PROX_MP),
        FECHA_MP: formatDate(item.FECHA_MP),
        EXCESO_EST: formatNumber(item.EXCESO_EST),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_mantencionesest_porcentaje.value = "no data";
      metrica_mantencionesest_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Mantenciones EST:",
      error.message
    );
  }
};

const loadRevisionesTecnicasData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaRevisionesTecnicas(
      fecha,
      SupId,
      CliId
    );

    const reporte = response.datametricarevisionestecnicas;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_revisionestecnicas_num.value = valoresNumericos[0].unidades;
      metrica_revisionestecnicas_porcentaje.value =
        valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      revisionestecnicasInfo.value = reporte.map((item) => ({
        ...item,
        Rev_TECNICA: formatDate(item.Rev_TECNICA),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_revisionestecnicas_porcentaje.value = "no data";
      metrica_revisionestecnicas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Técnicas:",
      error.message
    );
  }
};

const loadRevisionesGasesData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaRevisionesGases(
      fecha,
      SupId,
      CliId
    );

    const reporte = response.datametricarevisionesgases;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_revisionesgases_num.value = valoresNumericos[0].unidades;
      metrica_revisionesgases_porcentaje.value = valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      revisionesgasesInfo.value = reporte.map((item) => ({
        ...item,
        Rev_GASES: formatDate(item.Rev_GASES),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_revisionesgases_porcentaje.value = "no data";
      metrica_revisionesgases_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Revisiones Gases:",
      error.message
    );
  }
};

const loadReemplazosData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaReemplazos(fecha, SupId, CliId);

    const reporte = response?.datametricareemplazos;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_reemplazos_num.value = valoresNumericos[0].unidades;
      metrica_reemplazos_porcentaje.value = valoresNumericos[0].promedioDias;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      reemplazosInfo.value = reporte.map((item) => ({
        ...item,
        FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
        DIAS_REEMPLAZO: formatNumber(item.DIAS_REEMPLAZO),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_reemplazos_porcentaje.value = "no data";
      metrica_reemplazos_num.value = "no data";
    }
  } catch (error) {
    console.error("Error al cargar los datos de Reemplazos:", error.message);
  }
};

const loadUnidadesNuevasData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }

  try {
    const response = await getReporteMetricaUnidadesNuevas(fecha, SupId, CliId);

    const reporte = response?.datametricaunidadesnuevas;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_unidadesnuevas_num.value = valoresNumericos[0].unidadesNuevas;
      metrica_unidadesnuevas_promedio.value = valoresNumericos[0].promedio;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      unidadesnuevasInfo.value = reporte.map((item) => ({
        ...item,
        FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
        // FECHA_ALQUILADO: formatDate(item.FECHA_ALQUILADO),
        DIAS_DISPONIBLE: formatNumber(item.DIAS_DISPONIBLE),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_unidadesnuevas_promedio.value = "no data";
      metrica_unidadesnuevas_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Unidades Nuevas:",
      error.message
    );
  }
};

const loadProximosVencimientosData = async () => {
  let CliId = null;
  if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
    CliId = null;
  } else if (selectedClient.value) {
    CliId = selectedClient.value.unikey_CL;
  }

  let SupId = null;
  if (selectedSup.value && selectedSup.value.name_SU === "GENERAL") {
    SupId = null;
  } else if (selectedSup.value && selectedSup.value.name_SU !== "GENERAL") {
    SupId = selectedSup.value.unikey_SU;
  } else if (userRole.value === "manager") {
    SupId = Sup_Cod_Cartera.value;
  }
  try {
    const response = await getReporteMetricaProximosVencimientos(
      fecha,
      SupId,
      CliId
    );

    const reporte = response.datametricaproximosvencimientos;

    const valoresNumericos = response?.valores_Numericos;

    if (Array.isArray(valoresNumericos) && valoresNumericos.length > 0) {
      metrica_proximosvencimientos_num.value =
        valoresNumericos[0].proximosVencimientos;
      metrica_proximosvencimientos_porcentaje.value =
        valoresNumericos[0].porcentaje;
    }

    if (Array.isArray(reporte) && reporte.length > 0) {
      proximosvencimientosInfo.value = reporte.map((item) => ({
        ...item,
        FIN_LEASE: formatDate(item.FIN_LEASE),
        DIAS_VENCIMIENTO: formatNumber(item.DIAS_VENCIMIENTO),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    } else {
      metrica_proximosvencimientos_porcentaje.value = "no data";
      metrica_proximosvencimientos_num.value = "no data";
    }
  } catch (error) {
    console.error(
      "Error al cargar los datos de Próximos Vencimientos:",
      error.message
    );
  }
};

const isButtonEnabled = computed(() => selectedClient.value);

onBeforeMount(() => {
  watch(
    [Sup_Cod_Cartera, Numero_cliente],
    async ([newSupValue, newClientValue]) => {
      resetMetrics();

      if (newSupValue && newClientValue) {
        await loadAllMetrics();
      }
    },
    { immediate: true }
  );

  if (Sup_Cod_Cartera.value && Numero_cliente.value) {
    loadAllMetrics();
  }
});

watch([Sup_Cod_Cartera, Numero_cliente], async () => {
  resetMetrics();
});
</script>

<style scoped>
@import "../css/home.css";
</style>
