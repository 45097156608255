import { api } from "../services/api.js";

export async function getReporteMantenciones4D(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/mantenciones4d?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener la información de las mantenciones por KM pendientes:",
      error
    );
    throw error;
  }
}
