import { defineStore } from "pinia";
import { getReporteSupervisores } from "../api/supervisores.js";
import useUserStore from "./userStore.js";

export const useSupStore = defineStore("sup", {
  state: () => ({
    selectedSup: null,
    supervisors: [],
  }),
  actions: {
    setSelectedSup(supervisor) {
      this.selectedSup = supervisor;
    },

    clearSelectedSup() {
      this.selectedSup = null;
    },

    async loadSupervisors() {
      const userStore = useUserStore();
      const user = userStore.user;
      const userRole = user?.rol;
      const userId = user?.id;

      try {
        const fecha = new Date().toISOString().split("T")[0];

        if (userRole === "admin") {
          const response = await getReporteSupervisores(fecha);

          const reporteSupervisores = response.datasupervisores;

          if (Array.isArray(reporteSupervisores)) {
            this.supervisors = reporteSupervisores.map((supervisor) => ({
              unikey_SU: supervisor.Sup_Cod_Cartera,
              name_SU: supervisor.Sup_Nombre,
            }));
          }

          this.supervisors.unshift({
            unikey_SU: 100,
            name_SU: "GENERAL",
          });
        } else if (userRole === "manager") {
          this.supervisors = [
            {
              unikey_SU: userId,
              name_SU: userStore.user?.name || "Manager",
            },
          ];

          this.setSelectedSup(this.supervisors[0]);
        }
      } catch (error) {
        console.error("Error fetching supervisors:", error);
      }
    },
  },
});
