import { api } from "../services/api.js";

export async function getVistaClientes(fecha, id_supervisor, id_cliente) {
  try {
    const response = await api.get(
      `/reportes/vistaclientes?fecha='${fecha}'&id_supervisor=${id_supervisor}&id_cliente=${id_cliente}`
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener la información:", error);
    throw error;
  }
}
