<template>
  <div class="login-view">
    <div class="login-container">
      <div class="login-header"></div>
      <div class="login-content">
        <img :src="logoSrc" alt="Logo" class="login-logo" />
        <h2>PLATAFORMA LOP</h2>
        <form @submit.prevent="handleLogin" autocomplete="off">
          <input
            type="text"
            v-model="username"
            autocomplete="current-username"
            placeholder="Ingresar Nombre de usuario"
            required
          />
          <input
            type="password"
            v-model="password"
            autocomplete="current-password"
            placeholder="Ingresar Contraseña"
            required
          />
          <button
            type="submit"
            class="login-button"
            :disabled="!username || !password"
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useUserStore from "@/stores/userStore";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import Logo from "../assets/images/logo_fluor.png";

export default {
  name: "LoginView",
  data() {
    return {
      userStore: useUserStore(),
      router: useRouter(),
      username: "",
      password: "",
      logoSrc: Logo,
    };
  },
  methods: {
    async handleLogin() {
      try {
        await this.userStore.authenticateUser(this.username, this.password);

        this.$router.push({ name: "Home" });
      } catch (err) {
        alert("Nombre o contraseña incorrectos");
      }
    },
  },
  setup() {
    onMounted(() => {
      localStorage.clear();
    });

    return {};
  },
};
</script>

<style scoped>
@import "../css/login.css";
</style>
