import { defineStore } from "pinia";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import router from "@/router/router";

class User {
  constructor(json) {
    this.username = json?.username ?? "";
    this.nombres = json?.nombres ?? "";
    this.apellidos = json?.apellidos ?? "";
    this.id = json?.id ?? 0;
    this.rol = json?.rol ?? "";
  }
}

const useUserStore = defineStore("user", {
  state: () => ({
    token: sessionStorage.getItem("authToken") || null,
    user: null,
    id: null,
    rol: null,
    loading: true,
  }),

  getters: {
    isAuthenticated() {
      return !!this.user;
    },
  },

  actions: {
    async authenticateUser(userName, password) {
      this.loading = true;
      try {
        const userdata = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/login`,
          { username: userName, password }
        );

        const { user, access_token, refresh_token } = userdata.data;
        const { userName: username, nombres, apellidos } = user;
        const { codigoCartera: id, rol } = userdata.data.secciones[0];

        this.user = new User({ username, nombres, apellidos, id, rol });
        this.token = access_token;

        sessionStorage.setItem("authToken", this.token);
        sessionStorage.setItem("refreshToken", refresh_token);
        sessionStorage.setItem("user", JSON.stringify(this.user));

        this.startTokenRefreshInterval();
      } catch (error) {
        console.log("Error al autenticar usuario:", error);
        throw error;
      } finally {
        this.loading = false;
      }
    },

    async loadUserFromStorage() {
      this.loading = true;
      try {
        let storedToken = sessionStorage.getItem("authToken");

        if (storedToken) {
          const appStarted = sessionStorage.getItem("appStarted");
          const storedUser = sessionStorage.getItem("user");
          const decodedToken = jwtDecode(storedToken);
          const tokenExpirationTime = decodedToken.exp * 1000;
          const currentTime = new Date().getTime();

          if (!appStarted) {
            sessionStorage.setItem("appStarted", "true");
          }

          if (currentTime >= tokenExpirationTime) {
            const success = await this.refreshAccessToken();
            if (!success) {
              this.logout();
              return;
            }
            storedToken = sessionStorage.getItem("authToken");
          }
          if (storedUser && storedToken) {
            this.user = JSON.parse(storedUser);
            this.token = storedToken;
            this.rol = this.user?.rol || null;
            this.id = this.user?.id || null;

            this.startTokenRefreshInterval();
          } else {
            this.logout();
          }
        }
      } catch (error) {
        console.error(
          "Error al cargar el usuario desde el almacenamiento:",
          error
        );
      } finally {
        this.loading = false;
      }
    },

    async autoLoginFromQuery(token) {
      const userdata = await axios.post(
        `${process.env.VUE_APP_API_URL}/auth/autoLogin`,
        { token }
      );

      const { userName: username, nombres, apellidos } = userdata.data.user;

      const access_token = userdata.data.access_token;
      const id = userdata.data.secciones[0].codigoCartera;
      const rol = userdata.data.secciones[0].rol;

      this.user = new User({
        username,
        nombres,
        apellidos,
        id,
        rol,
      });

      this.token = access_token;
      sessionStorage.setItem("authToken", this.token);
      sessionStorage.setItem("user", JSON.stringify(this.user));
      sessionStorage.setItem("appStarted", "true");
    },

    async refreshAccessToken() {
      try {
        const refreshToken = sessionStorage.getItem("refreshToken");

        if (!refreshToken) {
          console.warn("No hay refresh token disponible, cerrando sesión.");
          return false;
        }

        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/auth/refresh`,
          { refreshToken }
        );
        const { access_token } = response.data;

        this.token = access_token;
        sessionStorage.setItem("authToken", this.token);
        return true;
      } catch (error) {
        console.error("Error al refrescar el token:", error);
        return false;
      }
    },

    startTokenRefreshInterval() {
      if (this.tokenRefreshInterval) {
        clearInterval(this.tokenRefreshInterval);
      }

      this.tokenRefreshInterval = setInterval(async () => {
        if (!this.token) return;

        const decodedToken = jwtDecode(this.token);
        const tokenExpirationTime = decodedToken.exp * 1000;
        const currentTime = new Date().getTime();
        const timeLeft = tokenExpirationTime - currentTime;

        if (timeLeft <= 5000) {
          const success = await this.refreshAccessToken();
          if (!success) {
            console.warn("No se pudo refrescar el token, cerrando sesión.");
            this.logout();
          }
        }
      }, 3000);
    },

    logout() {
      this.token = null;

      this.user = null;
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("refreshToken");
      sessionStorage.removeItem("user");
      sessionStorage.setItem("appStarted", "false");

      if (this.tokenRefreshInterval) {
        clearInterval(this.tokenRefreshInterval);
        this.tokenRefreshInterval = null;
      }

      router.push({ name: "Login" });
    },
  },
});

export default useUserStore;
