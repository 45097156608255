import { api } from "../services/api.js";

export async function getReporteSupervisores(fecha) {
  try {
    const response = await api.get(`/reportes/supervisores?fecha='${fecha}' `);
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener el reporte de los supervisores LOP activos actualmente:",
      error
    );
    throw error;
  }
}
