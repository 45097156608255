import { api } from "../services/api.js";

export async function getReporteMetricaCPA(fecha, id_supervisor, id_cliente) {
  try {
    let ruta = `/reportes/metrica_cpa?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica CPA:", error);
    throw error;
  }
}

export async function getReporteMetricaMantenciones4D(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_mantenciones4d?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Mantenciones4D:", error);
    throw error;
  }
}

export async function getReporteMetricaMantencionesEST(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_mantencionesest?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte MantencionesEST:", error);
    throw error;
  }
}

export async function getReporteMetricaRevisionesTecnicas(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_revisionestecnicas?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesTecnicas:", error);
    throw error;
  }
}

export async function getReporteMetricaRevisionesGases(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_revisionesgases?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte RevisionesGases:", error);
    throw error;
  }
}

export async function getReporteMetricaReemplazos(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_reemplazos?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte Reemplazos:", error);
    throw error;
  }
}

export async function getReporteMetricaUnidadesNuevas(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_unidadesnuevas?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Unidades Nuevas:", error);
    throw error;
  }
}

export async function getReporteMetricaProximosVencimientos(
  fecha,
  id_supervisor,
  id_cliente
) {
  try {
    let ruta = `/reportes/metrica_proximosvencimientos?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la métrica Próximos Vencimientos:", error);
    throw error;
  }
}
