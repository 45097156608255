<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <Tabla
      v-if="dataLoaded && cpaInfo.length > 0"
      :Info="cpaInfo"
      :subnum1="cpapendientes"
      :subnum2="cpapendientes3meses"
      :columnHeaders="
        selectedClient?.name_CL === 'GENERAL'
          ? [
              'Cliente',
              'Código CPA',
              'PPU',
              'CPA',
              'Cargos',
              'Fecha Apertura',
              'Días Aperturado',
            ]
          : [
              'Código CPA',
              'PPU',
              'CPA',
              'Cargos',
              'Fecha Apertura',
              'Días Aperturado',
            ]
      "
      :rowFields="
        selectedClient?.name_CL === 'GENERAL'
          ? [
              { key: 'CLIENTE', format: 'text' },
              { key: 'CODIGO_CPA', format: 'number' },
              { key: 'PPU', format: 'text' },
              { key: 'CPA', format: 'currency' },
              { key: 'Cargos', format: 'currency' },
              { key: 'FECHA_APERTURA', format: 'date' },
              { key: 'DIAS_APERTURADO', format: 'number' },
            ]
          : [
              { key: 'CODIGO_CPA', format: 'number' },
              { key: 'PPU', format: 'text' },
              { key: 'CPA', format: 'currency' },
              { key: 'Cargos', format: 'currency' },
              { key: 'FECHA_APERTURA', format: 'date' },
              { key: 'DIAS_APERTURADO', format: 'number' },
            ]
      "
      :numColspan="selectedClient?.name_CL === 'GENERAL' ? 7 : 6"
      :dataColspan="4"
      :subnumColspan="4"
      :enablePopup="false"
      :disabled="false"
      :enableFilters="
        selectedClient?.name_CL === 'GENERAL'
          ? [true, true, true, true, true, true, true]
          : [true, true, true, true, true, true]
      "
      subtable1="# CPA pendientes"
      subtable2="# CPA sobre 3 meses"
      tableTitle="CPA pendientes Histórico"
    />
    <p v-if="dataLoaded && cpaInfo.length === 0">
      El cliente {{ selectedClient.name_CL }} no presenta CPA pendientes
      históricos en la fecha seleccionada.
    </p>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useDateStore } from "../stores/dateStore.js";
import { getReporteCPA } from "../api/cpa.js";
import { formatNumber, formatDate, formatCurrency } from "../utils/formatters";

const cpaInfo = ref([]);
const cpapendientes = ref(null);
const cpapendientes3meses = ref(null);

const dataLoaded = ref(false);

const clientStore = useClientStore();
const selectedClient = computed(() => clientStore.selectedClient);

const supStore = useSupStore();
const selectedSup = computed(() => supStore.selectedSup);

const userStore = useUserStore();
const userRole = userStore.user?.rol;
const userId = userStore.user?.id;

const dateStore = useDateStore();
const selectedDate = ref(dateStore.selectedDate || null);

const loadData = async () => {
  try {
    let Sup_Cod_Cartera = null;

    if (userRole === "admin" && selectedSup.value.name_SU === "GENERAL") {
      Sup_Cod_Cartera = null;
    } else if (userRole === "manager" && !selectedSup.value) {
      Sup_Cod_Cartera = userId;
    } else if (selectedSup.value && selectedSup.value.unikey_SU) {
      Sup_Cod_Cartera = selectedSup.value.unikey_SU;
    }

    const fecha = new Date(selectedDate.value).toISOString().split("T")[0];

    let ClienteId = null;

    if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
      ClienteId = null;
    } else if (selectedClient.value) {
      ClienteId = selectedClient.value.unikey_CL;
    }

    const response = await getReporteCPA(fecha, Sup_Cod_Cartera, ClienteId);

    const reporteCPA = response.datacpa;

    const valoresNumericos = response.valores_Numericos;

    if (valoresNumericos.length > 0) {
      cpapendientes.value = valoresNumericos[0].pendientes;
      cpapendientes3meses.value = valoresNumericos[0].mayor3Meses || 0;
    }

    if (Array.isArray(reporteCPA)) {
      cpaInfo.value = reporteCPA.map((item) => ({
        ...item,
        PPU: item.PPU?.trim() ? item.PPU : "No registra patente",
        CPA: formatCurrency(item["CPA"]),
        Cargos: formatCurrency(item["Cargos"]),
        FECHA_APERTURA: formatDate(item.FECHA_APERTURA),
        DIAS_APERTURADO: formatNumber(item["DIAS_APERTURADO"]),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    }

    dataLoaded.value = true;
  } catch (error) {
    console.error("Error al obtener la información:", error);
    dataLoaded.value = true;
  }
};

onMounted(loadData);
</script>

<style scoped>
@import "../css/spinner.css";
</style>
