import { defineStore } from "pinia";
import { ref } from "vue";

export const useDateStore = defineStore("date", () => {
  const selectedDate = ref(null);

  const setSelectedDate = (date) => {
    selectedDate.value = date;
  };

  return { selectedDate, setSelectedDate };
});
