<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && mantencionespendientes4dInfo.length > 0"
        :Info="mantencionespendientes4dInfo"
        :subnum1="mantencionespendientes4dnum"
        :subnum2="mantencionespendientes4dporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM MP',
                'Fecha MP',
                'KM Prox MP',
                'Exceso KMS 4D',
              ]
            : [
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM MP',
                'Fecha MP',
                'KM Prox MP',
                'Exceso KMS 4D',
              ]
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'EXCESO_4D', format: 'number' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'EXCESO_4D', format: 'number' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 9 : 8"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true, true, true, true]
            : [true, true, true, true, true, true, true, true]
        "
        subtable1="# UNIDADES PENDIENTES"
        subtable2="% UNIDADES PENDIENTES"
        tableTitle="MANTENCIONES POR KM 4D PENDIENTES"
      />
      <p v-else-if="dataLoaded && mantencionespendientes4dInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        mantenciones por kilometraje en 4D pendientes.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && mantencionespendientesestInfo.length > 0"
        :Info="mantencionespendientesestInfo"
        :subnum1="mantencionespendientesestnum"
        :subnum2="mantencionespendientesestporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM Est',
                'KM MP',
                'Fecha MP',
                'KM Prox MP',
                'Exceso KMS Est',
              ]
            : [
                'PPU',
                'Codigo',
                'Región',
                'KM 4D',
                'KM Est',
                'KM MP',
                'Fecha MP',
                'KM Prox MP',
                'Exceso KMS Est',
              ]
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: number },
                { key: 'KMS_EST', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'EXCESO_EST', format: 'number' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM_4D', format: number },
                { key: 'KMS_EST', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
                { key: 'KM_PROX_MP', format: 'number' },
                { key: 'EXCESO_EST', format: 'number' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 10 : 9"
        :dataColspan="5"
        :subnumColspan="5"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true, true, true, true]
            : [true, true, true, true, true, true, true, true]
        "
        subtable1="# UNIDADES PENDIENTES"
        subtable2="% UNIDADES PENDIENTES"
        tableTitle="MANTENCIONES POR ESTIMACIÓN PENDIENTES"
      />
      <p v-else-if="dataLoaded && mantencionespendientesestInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        mantenciones por estimación pendientes.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionesgasespendientesInfo.length > 0"
        :Info="revisionesgasespendientesInfo"
        :subnum1="revisionesgasespendientesnum"
        :subnum2="revisionesgasespendientesporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
            : ['PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_GASES', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_GASES', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true]
            : [true, true, true, true]
        "
        subtable1="# FLOTA CON REVISIÓN GASES VENCIDA"
        subtable2="% FLOTA CON REVISIÓN GASES VENCIDA"
        tableTitle="REVISIONES GASES PENDIENTES"
      />
      <p v-else-if="dataLoaded && revisionesgasespendientesInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        revisiones pendientes.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionesgasesproxvencerInfo.length > 0"
        :Info="revisionesgasesproxvencerInfo"
        :subnum1="revisionesgasesproxvencernum"
        :subnum2="revisionesgasesproxvencerporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
            : ['PPU', 'Codigo', 'Ubicación', 'Rev. Gases']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_GASES', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_GASES', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true]
            : [true, true, true, true]
        "
        subtable1="# FLOTA CON REVISIÓN GASES PRÓXIMAS A VENCER"
        subtable2="% FLOTA CON REVISIÓN GASES PRÓXIMAS A VENCER"
        tableTitle="REVISIONES GASES PRÓXIMAS A VENCER"
      />
      <p v-else-if="dataLoaded && revisionesgasesproxvencerInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        revisiones de gases próximas a vencer (próximos 30 días).
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionestecnicaspendientesInfo.length > 0"
        :Info="revisionestecnicaspendientesInfo"
        :subnum1="revisionestecnicaspendientesnum"
        :subnum2="revisionestecnicaspendientesporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
            : ['PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_TECNICA', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_TECNICA', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true]
            : [true, true, true, true]
        "
        subtable1="# FLOTA CON REVISIÓN TÉCNICA VENCIDA"
        subtable2="% FLOTA CON REVISIÓN TÉCNICA VENCIDA"
        tableTitle="REVISIONES TECNICAS PENDIENTES"
      />
      <p
        v-else-if="dataLoaded && revisionestecnicaspendientesInfo.length === 0"
      >
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        revisiones técnicas pendientes.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && revisionestecnicasproxvencerInfo.length > 0"
        :Info="revisionestecnicasproxvencerInfo"
        :subnum1="revisionestecnicasproxvencernum"
        :subnum2="revisionestecnicasproxvencerporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? ['Cliente', 'PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
            : ['PPU', 'Codigo', 'Ubicación', 'Rev. Técnica']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_TECNICA', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'Rev_TECNICA', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 3 : 2"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true]
            : [true, true, true, true]
        "
        subtable1="# FLOTA CON REVISIÓN TÉCNICA PRÓXIMAS A VENCER"
        subtable2="% FLOTA CON REVISIÓN TÉCNICA PRÓXIMAS A VENCER"
        tableTitle="REVISIONES TECNICAS PRÓXIMAS A VENCER"
      />
      <p
        v-else-if="dataLoaded && revisionestecnicasproxvencerInfo.length === 0"
      >
        El cliente {{ selectedClient.name_CL }} no presenta unidades con
        revisiones técnicas próximas a vencer (próximos 30 días).
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { getReporteMantenciones4D } from "../api/mantencionespendientes4d.js";
import { getReporteMantencionesEST } from "../api/mantencionespendientesest.js";
import { getReporteRevisionesTecnicas } from "../api/revisionestecnicaspendientes.js";
import { getReporteRevisionesGases } from "../api/revisionesgasespendientes.js";
import { getReporteRevisionesGasesProxVencer } from "../api/revisionesgasesproxvencer.js";
import { getReporteRevisionesTecnicasProxVencer } from "../api/revisionestecnicasproxvencer.js";
import { formatNumber, formatDate } from "../utils/formatters";

const mantencionespendientes4dInfo = ref([]);
const mantencionespendientesestInfo = ref([]);
const revisionestecnicaspendientesInfo = ref([]);
const revisionesgasespendientesInfo = ref([]);
const revisionesgasesproxvencerInfo = ref([]);
const revisionestecnicasproxvencerInfo = ref([]);

const dataLoaded = ref(false);
const clientStore = useClientStore();
const supStore = useSupStore();
const userStore = useUserStore();

const selectedClient = computed(() => clientStore.selectedClient);
const selectedSup = computed(() => supStore.selectedSup);

const mantencionespendientes4dnum = ref(null);
const mantencionespendientes4dporcentaje = ref(null);
const mantencionespendientesestnum = ref(null);
const mantencionespendientesestporcentaje = ref(null);
const revisionestecnicaspendientesnum = ref(null);
const revisionestecnicaspendientesporcentaje = ref(null);
const revisionesgasespendientesnum = ref(null);
const revisionesgasespendientesporcentaje = ref(null);
const revisionesgasesproxvencernum = ref(null);
const revisionesgasesproxvencerporcentaje = ref(null);
const revisionestecnicasproxvencernum = ref(null);
const revisionestecnicasproxvencerporcentaje = ref(null);

const loadData = async () => {
  try {
    const userRole = userStore.user?.rol;
    const userId = userStore.user?.id;

    let Sup_Cod_Cartera = null;
    if (userRole === "admin" && selectedSup.value.name_SU === "GENERAL") {
      Sup_Cod_Cartera = null;
    } else if (userRole === "manager" && !selectedSup.value) {
      Sup_Cod_Cartera = userId;
    } else if (selectedSup.value && selectedSup.value.unikey_SU) {
      Sup_Cod_Cartera = selectedSup.value.unikey_SU;
    }

    const fecha = new Date().toISOString().split("T")[0];

    const SupId = Sup_Cod_Cartera;

    let ClienteId = null;
    if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
      ClienteId = null;
    } else if (selectedClient.value) {
      ClienteId = selectedClient.value.unikey_CL;
    }
    const responsemantenciones4d = await getReporteMantenciones4D(
      fecha,
      SupId,
      ClienteId
    );

    const reporteMantenciones4D = responsemantenciones4d.datamantenciones4d;

    const valoresNumericos__Mantenciones4D =
      responsemantenciones4d.valores_Numericos;

    if (valoresNumericos__Mantenciones4D.length > 0) {
      mantencionespendientes4dnum.value =
        valoresNumericos__Mantenciones4D[0].pendientes;
      mantencionespendientes4dporcentaje.value =
        valoresNumericos__Mantenciones4D[0].porcentaje;
    }

    if (Array.isArray(reporteMantenciones4D)) {
      mantencionespendientes4dInfo.value = reporteMantenciones4D.map(
        (item) => ({
          ...item,
          KM_MP: formatNumber(item["KM_MP"]),
          FECHA_MP: formatDate(item.FECHA_MP),
          KM_PROX_MP: formatNumber(item["KM_PROX_MP"]),
          EXCESO_4D: formatNumber(item["EXCESO_4D"]),
          Id_Supervisor: formatNumber(item["Id_Supervisor"]),
          Id_Cliente: formatNumber(item["Id_Cliente"]),
        })
      );
    }

    const responsemantencionesest = await getReporteMantencionesEST(
      fecha,
      SupId,
      ClienteId
    );

    const reporteMantencionesEST = responsemantencionesest.datamantencionesest;

    const valoresNumericos_MantencionesEST =
      responsemantencionesest.valores_Numericos;

    if (valoresNumericos_MantencionesEST.length > 0) {
      mantencionespendientesestnum.value =
        valoresNumericos_MantencionesEST[0].pendientes;
      mantencionespendientesestporcentaje.value =
        valoresNumericos_MantencionesEST[0].porcentaje;
    }

    if (Array.isArray(reporteMantencionesEST)) {
      mantencionespendientesestInfo.value = reporteMantencionesEST.map(
        (item) => ({
          ...item,
          CODIGO: item.CODIGO?.trim() ? item.CODIGO : "No registra código",
          KMS_EST: formatNumber(item["KMS_EST"]),
          KM_MP: formatNumber(item["KM_MP"]),
          FECHA_MP: formatDate(item.FECHA_MP),
          KM_PROX_MP: formatNumber(item["KM_PROX_MP"]),
          EXCESO_EST: formatNumber(item["EXCESO_EST"]),
          Id_Supervisor: formatNumber(item["Id_Supervisor"]),
          Id_Cliente: formatNumber(item["Id_Cliente"]),
        })
      );
    }

    const responserevisionesgases = await getReporteRevisionesGases(
      fecha,
      SupId,
      ClienteId
    );

    const reporteRevisionesGases = responserevisionesgases.datarevisionesgases;

    const valoresNumericos_RevisionesGases =
      responserevisionesgases.valores_Numericos;

    if (valoresNumericos_RevisionesGases.length > 0) {
      revisionesgasespendientesnum.value =
        valoresNumericos_RevisionesGases[0].pendientes;
      revisionesgasespendientesporcentaje.value =
        valoresNumericos_RevisionesGases[0].porcentaje;
    }

    if (Array.isArray(reporteRevisionesGases)) {
      revisionesgasespendientesInfo.value = reporteRevisionesGases.map(
        (item) => ({
          ...item,
          Rev_GASES: formatDate(item.Rev_GASES),
          Id_Supervisor: formatNumber(item["Id_Supervisor"]),
          Id_Cliente: formatNumber(item["Id_Cliente"]),
        })
      );
    }

    const responserevisionesgasesproxvencer =
      await getReporteRevisionesGasesProxVencer(fecha, SupId, ClienteId);

    const reporteRevisionesGasesProxVencer =
      responserevisionesgasesproxvencer.datarevisionesgasesproxvencer;

    const valoresNumericos_RevisionesGasesProxVencer =
      responserevisionesgasesproxvencer.valores_Numericos;

    if (valoresNumericos_RevisionesGasesProxVencer.length > 0) {
      revisionesgasesproxvencernum.value =
        valoresNumericos_RevisionesGasesProxVencer[0].pendientes;
      revisionesgasesproxvencerporcentaje.value =
        valoresNumericos_RevisionesGasesProxVencer[0].porcentaje;
    }

    if (Array.isArray(reporteRevisionesGasesProxVencer)) {
      revisionesgasesproxvencerInfo.value =
        reporteRevisionesGasesProxVencer.map((item) => ({
          ...item,
          Rev_GASES: formatDate(item.Rev_GASES),
          Id_Supervisor: formatNumber(item["Id_Supervisor"]),
          Id_Cliente: formatNumber(item["Id_Cliente"]),
        }));
    }

    const responserevisionestecnicas = await getReporteRevisionesTecnicas(
      fecha,
      SupId,
      ClienteId
    );

    const reporteRevisionesTecnicas =
      responserevisionestecnicas.datarevisionestecnicas;

    const valoresNumericos_RevisionesTecnicas =
      responserevisionestecnicas.valores_Numericos;

    if (valoresNumericos_RevisionesTecnicas.length > 0) {
      revisionestecnicaspendientesnum.value =
        valoresNumericos_RevisionesTecnicas[0].pendientes;
      revisionestecnicaspendientesporcentaje.value =
        valoresNumericos_RevisionesTecnicas[0].porcentaje;
    }

    if (Array.isArray(reporteRevisionesTecnicas)) {
      revisionestecnicaspendientesInfo.value = reporteRevisionesTecnicas.map(
        (item) => ({
          ...item,
          Rev_TECNICA: formatDate(item.Rev_TECNICA),
          Id_Supervisor: formatNumber(item["Id_Supervisor"]),
          Id_Cliente: formatNumber(item["Id_Cliente"]),
        })
      );
    }

    const responserevisionestecnicasproxvencer =
      await getReporteRevisionesTecnicasProxVencer(fecha, SupId, ClienteId);

    const reporteRevisionesTecnicasProxVencer =
      responserevisionestecnicasproxvencer.datarevisionestecnicasproxvencer;

    const valoresNumericos_RevisionesTecnicasProxVencer =
      responserevisionestecnicasproxvencer.valores_Numericos;

    if (valoresNumericos_RevisionesTecnicasProxVencer.length > 0) {
      revisionestecnicasproxvencernum.value =
        valoresNumericos_RevisionesTecnicasProxVencer[0].pendientes;
      revisionestecnicasproxvencerporcentaje.value =
        valoresNumericos_RevisionesTecnicasProxVencer[0].porcentaje;
    }

    if (Array.isArray(reporteRevisionesTecnicasProxVencer)) {
      revisionestecnicasproxvencerInfo.value =
        reporteRevisionesTecnicasProxVencer.map((item) => ({
          ...item,
          Rev_TECNICA: formatDate(item.Rev_TECNICA),
        }));
    }

    dataLoaded.value = true;
  } catch (error) {
    console.error("Error al cargar datos:", error);
    dataLoaded.value = true;
  }
};

onMounted(loadData);
</script>

<style scoped>
@import "../css/spinner.css";
</style>
