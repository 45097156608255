import { defineStore } from "pinia";
import { getReporteClientes } from "../api/clientes.js";
import { useSupStore } from "./supStore.js";
import useUserStore from "./userStore.js";

export const useClientStore = defineStore("client", {
  state: () => ({
    selectedClient: null,
    clients: [],
  }),

  actions: {
    async loadClients() {
      const supStore = useSupStore();
      const selectedSup = supStore.selectedSup;
      const userStore = useUserStore();
      const user = userStore.user;

      const userRole = user?.rol;
      const userId = user?.id;

      try {
        let clientsData = [];

        const fecha = new Date().toISOString().split("T")[0];

        if (userRole === "admin") {
          if (!supStore.selectedSup || !supStore.selectedSup.unikey_SU) {
            this.clients = [];
            return;
          }

          let Sup_Cod_Cartera = null;

          if (selectedSup.name_SU === "GENERAL") {
            Sup_Cod_Cartera = null;
          } else {
            Sup_Cod_Cartera = supStore.selectedSup.unikey_SU;
          }

          const response_Clientes = await getReporteClientes(
            fecha,
            Sup_Cod_Cartera
          );

          const reporteClientes = response_Clientes.dataclientes;

          if (Array.isArray(reporteClientes)) {
            clientsData = reporteClientes.map((cliente) => ({
              id_Reporte: cliente.id,
              unikey_CL: cliente.Unikey,
              name_CL: cliente.NomComp,
              cod_SU: cliente.Sup_Cod_Cartera,
            }));
          } else {
            console.error(
              "La estructura de los datos de clientes es incorrecta",
              reporteClientes
            );
          }
        } else if (userRole === "manager") {
          if (!userId) {
            console.error("No se encontró el ID del manager.");
            return;
          }

          const response_Clientes_Supervisor = await getReporteClientes(
            fecha,
            userId
          );

          const reporteClientesSupervisor =
            response_Clientes_Supervisor.dataclientes;

          if (Array.isArray(reporteClientesSupervisor)) {
            clientsData = reporteClientesSupervisor.map((cliente) => ({
              unikey_CL: cliente.Unikey,
              name_CL: cliente.NomComp,
            }));
          } else {
            console.error(
              "La estructura de los datos de clientes del supervisor es incorrecta",
              reporteClientesSupervisor
            );
          }
        }

        if (Array.isArray(clientsData)) {
          this.clients = clientsData;
        }

        this.clients.unshift({
          name_CL: "GENERAL",
          unikey_CL: 100,
        });
      } catch (error) {
        console.error("Error al cargar clientes:", error);
        this.clients = [];
      }
    },

    setSelectedClient(client) {
      this.selectedClient = client;
    },

    clearSelectedClient() {
      this.selectedClient = null;
    },
  },
});
