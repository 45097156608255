export const formatNumber = (value) => {
  if (value === "-" || value === 0 || value === "0" || value === "N/A") {
    return "0";
  }
  if (value === "OT Abierta") {
    return value;
  }
  return new Intl.NumberFormat().format(value);
};

export const formatDate = (value) => {
  if (value === "Pendiente" || !value) {
    return value || "N/A";
  }
  return new Date(value).toLocaleDateString("es-CL");
};

export const formatCurrency = (value) => {
  if (value === "Sin costo asociado" || value === "Sin cargo asociado") {
    return value;
  }
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
};
