<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && flotaInfo.length > 0"
        :Info="flotaInfo"
        :subnum1="flotanum"
        :subnum2="flotaporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Vehículo',
                'Región',
                'Kilometraje',
                'KM MP',
                'Fecha MP',
              ]
            : ['PPU', 'Vehículo', 'Región', 'Kilometraje', 'KM MP', 'Fecha MP']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'VEHICULO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'VEHICULO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'KM', format: 'number' },
                { key: 'KM_MP', format: 'number' },
                { key: 'FECHA_MP', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 7 : 6"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 4 : 3"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 4 : 3"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true, true]
            : [true, true, true, true, true, true]
        "
        subtable1="# UNIDADES EN FLOTA"
        subtable2="% FLOTA CLIENTE VS FLOTA SUPERVISOR"
        tableTitle="FLOTA"
      />
      <p v-else-if="dataLoaded && flotaInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no tiene asociada ninguna
        unidad.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && unidadesnuevasInfo.length > 0"
        :Info="unidadesnuevasInfo"
        :subnum1="unidadesnuevasnum"
        :subnum2="unidadesnuevaspromedio"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Codigo',
                'Region',
                'Ubicación',
                'Fecha Disponible',
              ]
            : ['PPU', 'Codigo', 'Region', 'Ubicación', 'Fecha Disponible']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'Patente', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'Region', format: 'text' },
                { key: 'Ubicacion', format: 'text' },
                { key: 'FECHA_DISPONIBLE', format: 'date' },
              ]
            : [
                { key: 'Patente', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'Region', format: 'text' },
                { key: 'Ubicacion', format: 'text' },
                { key: 'FECHA_DISPONIBLE', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 6 : 5"
        :dataColspan="3"
        :subnumColspan="3"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true]
            : [true, true, true, true, true]
        "
        subtable1="# UNIDADES PENDIENTES DE ENTREGA"
        subtable2="PROMEDIO DÍAS DE DISPONIBILIDAD"
        tableTitle="UNIDADES NUEVAS"
      />
      <p v-else-if="dataLoaded && unidadesnuevasInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades nuevas
        asociadas.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && unidadesprelopInfo.length > 0"
        :Info="unidadesprelopInfo"
        :subnum1="unidadesprelopnum"
        :subnum2="unidadespreloppromedio"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Codigo',
                'Región',
                'Fecha Entrega',
                'Días PRE-LOP',
              ]
            : ['PPU', 'Codigo', 'Región', 'Fecha Entrega', 'Días PRE-LOP']
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'FECHA_ENTREGA', format: 'date' },
                { key: 'DIAS_PRE_LOP', format: 'number' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'REGION', format: 'text' },
                { key: 'FECHA_ENTREGA', format: 'date' },
                { key: 'DIAS_PRE_LOP', format: 'number' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 6 : 5"
        :dataColspan="3"
        :subnumColspan="3"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true]
            : [true, true, true, true, true]
        "
        subtable1="# UNIDADES PRE-LOP"
        subtable2="PROMEDIO DÍAS PRE-LOP"
        tableTitle="UNIDADES PRE-LOP"
      />
      <p v-else-if="dataLoaded && unidadesprelopInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades Pre-LOP
        asociadas.
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { getReporteFlota } from "../api/flota.js";
import { getReporteUnidadesNuevas } from "../api/unidadesnuevas.js";
import { getReportePreLop } from "../api/unidadesprelop.js";
import { formatNumber, formatDate } from "../utils/formatters";

const flotaInfo = ref([]);
const unidadesnuevasInfo = ref([]);
const unidadesprelopInfo = ref([]);

const dataLoaded = ref(false);

const flotanum = ref(null);
const flotaporcentaje = ref(null);
const unidadesnuevasnum = ref(null);
const unidadesnuevaspromedio = ref(null);
const unidadesprelopnum = ref(null);
const unidadespreloppromedio = ref(null);

const clientStore = useClientStore();
const supStore = useSupStore();
const userStore = useUserStore();

const selectedClient = computed(() => clientStore.selectedClient);
const selectedSup = computed(() => supStore.selectedSup);

const loadData = async () => {
  try {
    const userRole = userStore.user?.rol;
    const userId = userStore.user?.id;

    let Sup_Cod_Cartera = null;
    if (userRole === "admin" && selectedSup.value.name_SU === "GENERAL") {
      Sup_Cod_Cartera = null;
    } else if (userRole === "manager" && !selectedSup.value) {
      Sup_Cod_Cartera = userId;
    } else if (selectedSup.value && selectedSup.value.unikey_SU) {
      Sup_Cod_Cartera = selectedSup.value.unikey_SU;
    }

    const fecha = new Date().toISOString().split("T")[0];

    const SupId = Sup_Cod_Cartera;

    let ClienteId = null;
    if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
      ClienteId = null;
    } else if (selectedClient.value) {
      ClienteId = selectedClient.value.unikey_CL;
    }

    const responseflota = await getReporteFlota(fecha, SupId, ClienteId);

    const valoresNumericos_Flota = responseflota.valores_Numericos;

    if (valoresNumericos_Flota.length > 0) {
      flotanum.value = valoresNumericos_Flota[0].flota;
      flotaporcentaje.value = valoresNumericos_Flota[0].porcentaje;
    }

    const reporteFlota = responseflota.dataflota;

    if (Array.isArray(reporteFlota)) {
      flotaInfo.value = reporteFlota.map((item) => ({
        ...item,
        KM: formatNumber(item["KM"]),
        KM_MP: formatNumber(item["KM_MP"]),
        FECHA_MP: formatDate(item.FECHA_MP),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    }

    const responseunidadesnuevas = await getReporteUnidadesNuevas(
      fecha,
      SupId,
      ClienteId
    );

    const reporteUnidadesNuevas = responseunidadesnuevas.dataunidadesnuevas;

    const valoresNumericos_UnidadesNuevas =
      responseunidadesnuevas.valores_Numericos;

    if (valoresNumericos_UnidadesNuevas.length > 0) {
      unidadesnuevasnum.value = valoresNumericos_UnidadesNuevas[0].unidades;
      unidadesnuevaspromedio.value =
        valoresNumericos_UnidadesNuevas[0].promedioDias;
    }

    if (Array.isArray(reporteUnidadesNuevas)) {
      unidadesnuevasInfo.value = reporteUnidadesNuevas.map((item) => ({
        ...item,
        FECHA_DISPONIBLE: formatDate(item.FECHA_DISPONIBLE),
      }));
    }

    const responseprelop = await getReportePreLop(fecha, SupId, ClienteId);

    const reportePreLop = responseprelop.dataprelop;

    const valoresNumericos_Prelop = responseprelop.valores_Numericos;

    if (valoresNumericos_Prelop.length > 0) {
      unidadesprelopnum.value = valoresNumericos_Prelop[0].unidades;
      unidadespreloppromedio.value = valoresNumericos_Prelop[0].promedioDias;
    }

    if (Array.isArray(reportePreLop)) {
      unidadesprelopInfo.value = reportePreLop.map((item) => ({
        ...item,
        FECHA_ENTREGA: formatDate(item.FECHA_ENTREGA),
        DIAS_PRE_LOP: Math.abs(formatNumber(item["DIAS_PRE_LOP"])),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    }

    dataLoaded.value = true;
  } catch (error) {
    console.error("Error loading data:", error);
    dataLoaded.value = true;
  }
};

onMounted(loadData);
</script>

<style scoped>
@import "../css/spinner.css";
</style>
