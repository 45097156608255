import { api } from "../services/api.js";

export async function getReportePreLop(fecha, id_supervisor, id_cliente) {
  try {
    let ruta = `/reportes/prelop?fecha='${fecha}'`;

    ruta += id_supervisor ? `&id_supervisor=${id_supervisor}` : "";
    ruta += id_cliente ? `&id_cliente=${id_cliente}` : "";

    const response = await api.get(ruta);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el reporte prelop:", error);
    throw error;
  }
}
