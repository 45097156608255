<template>
  <div class="menu-wrapper">
    <ul class="menu-items">
      <li>
        <button
          class="menu-button"
          :class="{ active: isActive('Home') }"
          @click="handleClick('Home')"
        >
          <img :src="homeIcon" class="menu-icon" />
          <span class="button-text">Home</span>
        </button>
      </li>
      <li>
        <button
          class="menu-button"
          :class="{ active: isActive('Historic') }"
          @click="handleClick('Historic')"
        >
          <img :src="calendarIcon" alt="Histórico Icon" class="menu-icon" />
          <span class="button-text">Histórico</span>
        </button>
      </li>
      <li>
        <button
          class="menu-button"
          :class="{ active: isActive('Clientes') }"
          @click="handleClick('Clientes')"
        >
          <img :src="usersIcon" alt="Histórico Icon" class="menu-icon" />
          <span class="button-text">Clientes</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import home from "../assets/images/home.svg";
import calendar from "../assets/images/calendario.svg";
import users from "../assets/images/users.svg";

export default defineComponent({
  name: "MenuButtons",
  data() {
    return {
      homeIcon: home,
      calendarIcon: calendar,
      usersIcon: users,
    };
  },
  setup() {
    const router = useRouter();

    const isActive = (routeName) => {
      return router.currentRoute.value.name === routeName;
    };

    const handleClick = (routeName) => {
      router.push({ name: routeName });
    };

    return {
      isActive,
      handleClick,
    };
  },
});
</script>

<style scoped>
@import "../css/menu.css";
</style>
