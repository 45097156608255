<template>
  <button class="icon-button" @click="handleClick">
    <i v-if="icon" :class="`fa ${icon}`" class="icon-button__icon"></i>
    <div class="icon-button__text">
      <span class="icon-button__text--primary">{{ primaryText }}</span>
      <span class="icon-button__text--secondary">{{ secondaryText }}</span>
    </div>
  </button>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "IconButton",
  props: {
    icon: {
      type: String,
      required: false,
    },
    primaryText: {
      type: String,
      required: false,
    },
    secondaryText: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();

    const navigate = () => {
      if (!props.disabled) {
        router.push(props.to);
      }
    };

    const handleClick = () => {
      if (!props.disabled) {
        emit("click");
        navigate();
      }
    };

    return {
      handleClick,
    };
  },
});
</script>

<style scoped>
@import "../css/boton.css";
</style>
