<template>
  <div class="margen_total">
    <p class="letter" v-if="!selectedSup && userRole !== 'manager'">
      Selecciona un supervisor para visualizar los datos.
    </p>

    <div v-else-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
  </div>

  <div class="clientes-table-container" v-if="dataLoaded">
    <Tabla
      v-if="clientesInfo.length > 0"
      :Info="clientesInfo"
      :columnHeaders="[
        'Rut',
        'Cliente',
        'Ejecutivo',
        'Flota Actual',
        'Reemplazos Activos',
        'F/S',
        '% F/S',
        'Contratos por vencer',
        'Siniestros',
        '% Siniestros',
        'Robos y PT',
      ]"
      :rowFields="[
        { key: 'Cedula', format: 'text' },
        { key: 'NomComp', format: 'text' },
        { key: 'Ejecutivo', format: 'text' },
        { key: 'Vehiculos_Activos', format: 'number' },
        { key: 'Reemplazos_Activos', format: 'number' },
        { key: 'Unidades_Fuera_Servicio', format: 'number' },
        { key: 'Porcentaje_Unidades_Fuera_Servicio', format: 'number' },
        { key: 'Contratos_Proximos_Vencer', format: 'number' },
        { key: 'Siniestros_ultimo_ano', format: 'number' },
        { key: 'Porcentaje_siniestros', format: 'number' },
        { key: 'Robos_Perdidas_Totales', format: 'number' },
      ]"
      tableTitle="CLIENTES"
      :numColspan="11"
      :subnumColspan="6"
      :dataColspan="6"
      :enablePopup="false"
      :enableFilters="[
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
        true,
      ]"
    />
    <p v-else>No hay información disponible.</p>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { getVistaClientes } from "../api/vistacliente.js";

const clientesInfo = ref([]);

const supStore = useSupStore();
const userStore = useUserStore();

const userRole = computed(() => userStore.user?.rol);
const userId = userStore.user?.id || null;
const selectedSup = computed(() => supStore.selectedSup);
const dataLoaded = ref(false);

const loadData = async () => {
  dataLoaded.value = false;

  try {
    let Sup_Cod_Cartera = null;

    if (userRole.value === "manager") {
      Sup_Cod_Cartera = userId;
    } else if (selectedSup.value && selectedSup.value.unikey_SU) {
      Sup_Cod_Cartera = selectedSup.value.unikey_SU;
    } else {
      dataLoaded.value = true;
      return;
    }

    const fecha = new Date().toISOString().split("T")[0];

    const response = await getVistaClientes(fecha, Sup_Cod_Cartera);

    const reporteClientes = response.datavistaclientes;

    if (Array.isArray(reporteClientes)) {
      clientesInfo.value = reporteClientes.map((item) => ({
        ...item,
        Porcentaje_Unidades_Fuera_Servicio:
          item.Porcentaje_Unidades_Fuera_Servicio + "%",
        Porcentaje_siniestros: item.Porcentaje_siniestros + "%",
      }));
    }

    dataLoaded.value = true;
  } catch (error) {
    console.error("Error al cargar datos:", error);
    dataLoaded.value = true;
  }
};

watch(
  [selectedSup, userRole],
  ([newSup, role]) => {
    if (newSup || role === "manager") {
      loadData();
    }
  },
  { immediate: true }
);
</script>

<style scoped>
@import "../css/clientes.css";
</style>
