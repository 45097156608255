<template>
  <nav class="navbar-bottom">
    <ul>
      <li v-if="!isLoginPage">
        <router-link class="extras" to="/" exact-active-class="active">
          Home
        </router-link>
        <router-link class="extras" to="/Historic" exact-active-class="active">
          Histórico
        </router-link>
        <router-link class="extras" to="/Clientes" exact-active-class="active">
          Clientes
        </router-link>
      </li>

      <li class="dropdown" v-if="!isLoginPage && !isClientsPage">
        <div
          v-if="
            isCPAPage ||
            isFlotaPreLOPUniNuevasPage ||
            isMantencionesRevisionesPage ||
            isReemplazosFueraServicioPage
          "
          class="client-selected"
        >
          {{ selectedClient ? selectedClient.name_CL : "Clientes" }}
        </div>

        <div v-else>
          <button class="dropdown-button" @click="toggleDropdown">
            {{ selectedClient ? selectedClient.name_CL : "Clientes" }}
            <i class="fa-solid fa-caret-down"></i>
          </button>

          <div class="dropdown-content" v-if="dropdownVisible">
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Buscar cliente"
              class="dropdown-search"
            />

            <a
              v-for="client in filteredClients"
              :key="client.unikey_CL"
              href="#"
              @click="selectItem(client)"
              :class="{
                'selected-client':
                  selectedClient &&
                  selectedClient.unikey_CL === client.unikey_CL,
              }"
            >
              {{ client.name_CL }}
            </a>

            <div v-if="filteredClients.length === 0">
              No se encontró el cliente
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { useRoute } from "vue-router";

export default {
  setup() {
    const dropdownVisible = ref(false);
    const searchQuery = ref("");
    const route = useRoute();

    const clientStore = useClientStore();
    const supStore = useSupStore();
    const userStore = useUserStore();

    const selectedClient = computed(() => clientStore.selectedClient);

    const clients = computed(() => clientStore.clients);

    const filteredClients = computed(() => {
      const query = searchQuery.value.toLowerCase();
      return clients.value.filter((client) =>
        client.name_CL.toLowerCase().includes(query)
      );
    });

    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
      if (!dropdownVisible.value) searchQuery.value = "";
    };

    const selectItem = (client) => {
      clientStore.setSelectedClient(client);
      dropdownVisible.value = false;
    };

    watch(
      () => supStore.selectedSup,
      (newSup) => {
        if (newSup && newSup.unikey_SU) {
          clientStore.loadClients();
          clientStore.clearSelectedClient();
        }
      },
      { immediate: true }
    );

    watch(
      () => userStore.isAuthenticated,
      async (isAuthenticated) => {
        if (isAuthenticated) {
          await clientStore.loadClients();
        }
      },
      { immediate: true }
    );

    const handleClickOutside = (event) => {
      const dropdown = document.querySelector(
        ".navbar-bottom .dropdown-content"
      );
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !event.target.closest(".dropdown-button")
      ) {
        dropdownVisible.value = false;
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dropdownVisible.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    });

    const isLoginPage = computed(() => route.name === "Login");
    const isCPAPage = computed(() => route.name === "Cpa");
    const isFlotaPreLOPUniNuevasPage = computed(
      () => route.name === "FlotaUnidadesNuevasPreLOP"
    );
    const isMantencionesRevisionesPage = computed(
      () => route.name === "MantencionesyRevisionesPendientes"
    );
    const isReemplazosFueraServicioPage = computed(
      () => route.name === "ReemplazosFueraServicio"
    );
    const isClientsPage = computed(() => route.name === "Clientes");

    return {
      dropdownVisible,
      searchQuery,
      filteredClients,
      toggleDropdown,
      selectItem,
      selectedClient,
      clients,
      isLoginPage,
      isCPAPage,
      isFlotaPreLOPUniNuevasPage,
      isMantencionesRevisionesPage,
      isReemplazosFueraServicioPage,
      isClientsPage,
    };
  },
};
</script>

<style scoped>
@import "../css/navbarbottom.css";
</style>
