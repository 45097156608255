import { createRouter, createWebHistory } from "vue-router";
import Login from "../components/Login.vue";
import AutoLogin from "../components/AutoLogin.vue";
import Home from "../views/HomeView.vue";
import Historic from "../views/Home_Historico.vue";
import Vista_Clientes from "../views/Vista_Clientes.vue";
import FlotaUnidadesNuevasPreLOP from "../views/Flota_PreLOP_Unidades_Nuevas.vue";
import Cpa from "../views/CPA.vue";
import MantencionesyRevisionesPendientes from "../views/Mantenciones_Revisiones_Pendientes.vue";
import ReemplazosFueraServicio from "../views/Reemplazos_Fuera_Servicio.vue";
import useUserStore from "@/stores/userStore";
import CPAHistorico from "@/views/CPA_Historico.vue";
import FlotaUnidadesNuevasPreLOPHistorico from "@/views/Flota_PreLOP_Unidades_Nuevas_Historico.vue";
import MantencionesyRevisionesPendientesHistorico from "@/views/Mantenciones_Revisiones_Pendientes_Historico.vue";
import ReemplazosFueraServicioHistorico from "@/views/Reemplazos_Fuera_Servicio_Historico.vue";

const routes = [
  {
    path: "/Login",
    meta: {
      free: true,
    },
    children: [
      { path: "", name: "Login", component: Login },
      {
        path: "auto",
        name: "AutoLogin",
        component: AutoLogin,
      },
    ],
  },

  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Historic",
    name: "Historic",
    component: Historic,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Clientes",
    name: "Clientes",
    component: Vista_Clientes,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },

  {
    path: "/FlotaUnidadesNuevasPreLOP",
    name: "FlotaUnidadesNuevasPreLOP",
    component: FlotaUnidadesNuevasPreLOP,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/FlotaUnidadesNuevasPreLOPHistorico",
    name: "FlotaUnidadesNuevasPreLOPHistorico",
    component: FlotaUnidadesNuevasPreLOPHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/Cpa",
    name: "Cpa",
    component: Cpa,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/CpaHistorico",
    name: "CpaHistorico",
    component: CPAHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/MantencionesyRevisionesPendientes",
    name: "MantencionesyRevisionesPendientes",
    component: MantencionesyRevisionesPendientes,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/MantencionesyRevisionesPendientesHistorico",
    name: "MantencionesyRevisionesPendientesHistorico",
    component: MantencionesyRevisionesPendientesHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/ReemplazosFueraServicio",
    name: "ReemplazosFueraServicio",
    component: ReemplazosFueraServicio,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
  {
    path: "/ReemplazosFueraServicioHistorico",
    name: "ReemplazosFueraServicioHistorico",
    component: ReemplazosFueraServicioHistorico,
    meta: {
      admin: true,
      manager: true,
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();

  const isFreeRoute = to.matched.some((record) => record.meta.free);

  const isAuthenticated = !!userStore.token;

  if (isFreeRoute) {
    next();
    return;
  }

  if (!isAuthenticated) {
    if (to.name !== "Login") {
      next({ name: "Login" });
    } else {
      next();
    }
    return;
  }

  const roleUser = userStore.user?.rol;

  if (
    roleUser === "manager" &&
    to.matched.some((record) => record.meta.manager)
  ) {
    next();
    return;
  }

  if (roleUser === "admin" && to.matched.some((record) => record.meta.admin)) {
    next();
    return;
  }

  if (to.name !== "Home") {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
