<template>
  <div class="margen_total">
    <div v-if="!dataLoaded" class="loading-spinner">
      <i class="fa-solid fa-spinner fa-spin"></i>
      <div class="spinner"></div>
    </div>
    <div class="table-container">
      <Tabla
        v-if="dataLoaded && reemplazosInfo.length > 0"
        :Info="reemplazosInfo"
        :subnum1="reemplazosnum"
        :subnum2="reemplazosentrega"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU Reemplazo',
                'Código Reemplazo',
                'PPU Titular',
                'Código Titular',
                'Status Titular',
                'Región',
                'Fecha Reemplazo',
                'Días Reemplazo Abierto',
                'Días Cierre OT',
              ]
            : [
                'PPU Reemplazo',
                'Código Reemplazo',
                'PPU Titular',
                'Código Titular',
                'Status Titular',
                'Región',
                'Fecha Reemplazo',
                'Días Reemplazo Abierto',
                'Días Cierre OT',
              ]
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU_REEMPLAZO', format: 'text' },
                { key: 'CODIGO_REEMPLAZO', format: 'text' },
                { key: 'PPU_ORIGINAL', format: 'text' },
                { key: 'CODIGO_ORIGINAL', format: 'text' },
                { key: 'STATUS_ORIGINAL', format: 'number' },
                { key: 'REGION', format: 'text' },
                { key: 'FECHA_REEMPLAZO', format: 'date' },
                { key: 'DIAS_REEMPLAZO', format: 'number' },
                { key: 'DIAS_CIERRE_REMP', format: 'number' },
              ]
            : [
                { key: 'PPU_REEMPLAZO', format: 'text' },
                { key: 'CODIGO_REEMPLAZO', format: 'text' },
                { key: 'PPU_ORIGINAL', format: 'text' },
                { key: 'CODIGO_ORIGINAL', format: 'text' },
                { key: 'STATUS_ORIGINAL', format: 'number' },
                { key: 'REGION', format: 'text' },
                { key: 'FECHA_REEMPLAZO', format: 'date' },
                { key: 'DIAS_REEMPLAZO', format: 'number' },
                { key: 'DIAS_CIERRE_REMP', format: 'number' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 10 : 9"
        :dataColspan="5"
        :subnumColspan="5"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true, true, true, true, true]
            : [true, true, true, true, true, true, true, true, true]
        "
        subtable1="# UNIDADES REEMPLAZO ACTIVO"
        subtable2="# UNIDADES DISPONIBLES PARA ENTREGA A CLIENTE"
        tableTitle="UNIDADES REEMPLAZO"
      />
      <p v-else-if="dataLoaded && reemplazosInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta reemplazos activos.
      </p>
    </div>

    <div class="table-container">
      <Tabla
        v-if="dataLoaded && fueraservicioInfo.length > 0"
        :Info="fueraservicioInfo"
        :subnum1="fueraservicionum"
        :subnum2="fueraservicioporcentaje + '%'"
        :columnHeaders="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                'Cliente',
                'PPU',
                'Codigo',
                'Reemplazo',
                'Ubicación',
                'Taller',
                'Fecha FS',
                'Días FS',
                'Fecha Entrega',
              ]
            : [
                'PPU',
                'Codigo',
                'Reemplazo',
                'Ubicación',
                'Taller',
                'Fecha FS',
                'Días FS',
                'Fecha Entrega',
              ]
        "
        :rowFields="
          selectedClient?.name_CL === 'GENERAL'
            ? [
                { key: 'CLIENTE', format: 'text' },
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'Reemplazo', format: 'text' },
                { key: 'UBICACION', format: 'text' },
                { key: 'TALLER', format: 'text' },
                { key: 'FECHA_FS', format: 'date' },
                { key: 'DIAS_FS', format: 'number' },
                { key: 'FECHA_ENTREGA', format: 'date' },
              ]
            : [
                { key: 'PPU', format: 'text' },
                { key: 'CODIGO', format: 'text' },
                { key: 'Reemplazo', format: 'text' },
                { key: 'UBICACION', format: 'text' },
                { key: 'TALLER', format: 'text' },
                { key: 'FECHA_FS', format: 'date' },
                { key: 'DIAS_FS', format: 'number' },
                { key: 'FECHA_ENTREGA', format: 'date' },
              ]
        "
        :numColspan="selectedClient?.name_CL === 'GENERAL' ? 9 : 8"
        :dataColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :subnumColspan="selectedClient?.name_CL === 'GENERAL' ? 5 : 4"
        :enablePopup="false"
        :disabled="false"
        :enableFilters="
          selectedClient?.name_CL === 'GENERAL'
            ? [true, true, true, true, true, true, true, true, true]
            : [true, true, true, true, true, true, true, true]
        "
        subtable1="# UNIDADES FUERA DE SERVICIO"
        subtable2="% FLOTA FUERA DE SERVICIO"
        tableTitle="UNIDADES FUERA DE SERVICIO"
      />
      <p v-else-if="dataLoaded && fueraservicioInfo.length === 0">
        El cliente {{ selectedClient.name_CL }} no presenta unidades fuera de
        servicio.
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import Tabla from "../components/Tabla_botones.vue";
import { useClientStore } from "../stores/clientStore.js";
import { useSupStore } from "../stores/supStore.js";
import useUserStore from "../stores/userStore.js";
import { getReporteReemplazos } from "../api/reemplazos.js";
import { getReporteFueraServicio } from "../api/fueraservicio.js";
import { formatNumber, formatDate } from "../utils/formatters";

const clientStore = useClientStore();
const supStore = useSupStore();
const userStore = useUserStore();

const selectedClient = computed(() => clientStore.selectedClient);
const selectedSup = computed(() => supStore.selectedSup);

const reemplazosInfo = ref([]);
const fueraservicioInfo = ref([]);
const reemplazosnum = ref(null);
const reemplazosentrega = ref(null);
const fueraservicionum = ref(null);
const fueraservicioporcentaje = ref(null);

const dataLoaded = ref(false);

onMounted(async () => {
  try {
    const userRole = userStore.user?.rol;
    const userId = userStore.user?.id;

    let Sup_Cod_Cartera = null;
    if (userRole === "admin" && selectedSup.value.name_SU === "GENERAL") {
      Sup_Cod_Cartera = null;
    } else if (userRole === "manager" && !selectedSup.value) {
      Sup_Cod_Cartera = userId;
    } else if (selectedSup.value && selectedSup.value.unikey_SU) {
      Sup_Cod_Cartera = selectedSup.value.unikey_SU;
    }

    const fecha = new Date().toISOString().split("T")[0];

    const SupId = Sup_Cod_Cartera;

    let ClienteId = null;
    if (selectedClient.value && selectedClient.value.name_CL === "GENERAL") {
      ClienteId = null;
    } else if (selectedClient.value) {
      ClienteId = selectedClient.value.unikey_CL;
    }

    const responsereemplazos = await getReporteReemplazos(
      fecha,
      SupId,
      ClienteId
    );

    const reporteReemplazos = responsereemplazos.datareemplazos;

    const valoresNumericos_Reemplazos = responsereemplazos.valores_Numericos;

    if (valoresNumericos_Reemplazos.length > 0) {
      reemplazosnum.value = valoresNumericos_Reemplazos[0].unidades;
      reemplazosentrega.value =
        valoresNumericos_Reemplazos[0].unidadesDisponibles;
    }

    if (Array.isArray(reporteReemplazos)) {
      reemplazosInfo.value = reporteReemplazos.map((item) => ({
        ...item,
        CODIGO_REEMPLAZO: item.CODIGO_REEMPLAZO?.trim()
          ? item.CODIGO_REEMPLAZO
          : "No registra código",
        CODIGO_ORIGINAL: item.CODIGO_ORIGINAL?.trim()
          ? item.CODIGO_ORIGINAL
          : "No registra código",
        STATUS_ORIGINAL: formatNumber(item["STATUS_ORIGINAL"]),
        FECHA_REEMPLAZO: formatDate(item.FECHA_REEMPLAZO),
        DIAS_REEMPLAZO: formatNumber(item["DIAS_REEMPLAZO"]),
        DIAS_CIERRE_REMP: formatNumber(item["DIAS_CIERRE_REMP"]),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    }

    const responsefueraservicio = await getReporteFueraServicio(
      fecha,
      SupId,
      ClienteId
    );

    const reporteFueraServicio = responsefueraservicio.datafueraservicio;

    const valoresNumericos_FueraServicio =
      responsefueraservicio.valores_Numericos;

    if (valoresNumericos_FueraServicio.length > 0) {
      fueraservicionum.value = valoresNumericos_FueraServicio[0].fueraServicio;
      fueraservicioporcentaje.value =
        valoresNumericos_FueraServicio[0].porcentaje;
    }

    if (Array.isArray(reporteFueraServicio)) {
      fueraservicioInfo.value = reporteFueraServicio.map((item) => ({
        ...item,
        FECHA_FS: formatDate(item.FECHA_FS),
        DIAS_FS: formatNumber(item["DIAS_FS"]),
        FECHA_ENTREGA: formatDate(item.FECHA_ENTREGA),
        Id_Supervisor: formatNumber(item["Id_Supervisor"]),
        Id_Cliente: formatNumber(item["Id_Cliente"]),
      }));
    }

    dataLoaded.value = true;
  } catch (error) {
    console.error("Error al cargar datos:", error);
    dataLoaded.value = true;
  }
});
</script>

<style scoped>
@import "../css/spinner.css";
</style>
